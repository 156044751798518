import dayjs from 'dayjs';
import { processResponse, sendGetRequest } from '../dataRequestUtils';

const ignoreErrorCodes = [404];

const dateFilterFields = [
    'processedAtStart',
    'messageStartDate',
    'receivedAtStart',
    'messageEndDate',
    'processedAtEnd',
    'receivedAtEnd',
];

/**
 * Parse HL7 message date filter value for request
 * @param filters object
 * @returns object
 */
export const parseHL7MessageDateFilterValuesForRequest = (filters) => {
    if (!filters) return {};
    const parsedFilters = {};

    for (const o in filters) {
        let field = o;
        const val = filters?.[o] ? filters[o]?.trim?.() : '';

        switch (o) {
            case 'messageStartDate':
                field = 'startTime';
                break;

            case 'messageEndDate':
                field = 'endTime';
                break;

            case 'processed':
                parsedFilters['unprocessed'] = val === 'true' ? 'false' : 'true';
                break;

            case 'messageCode':
                parsedFilters['messageType'] = val;
                break;

            case 'messageTriggerEvent':
                parsedFilters['eventType'] = val;
                break;

            case 'attendingProviders':
                parsedFilters['attendingProvider'] = val;
                break;

            default:
                parsedFilters[field] = val;
                break;
        }

        if (val && dateFilterFields.includes(o)) {
            parsedFilters[field] = dayjs(val).format('YYYY-MM-DDTHH:mm:ss');
        }
    }

    return parsedFilters;
};

export const normalizeHL7MessageSortColumnsForRequest = (sorts) => {
    if (!sorts) return {};
    const normalizedSorts = {};

    for (const o in sorts) {
        if (o === 'createdAt') {
            continue;
        }

        normalizedSorts[`data.${o}`] = sorts[o];
    }

    return normalizedSorts;
};

/**
 *
 * @param successCallback function
 * @param finallyCallback function
 * @param appInstanceId string
 * @param errorCallback function
 * @param searchText string
 * @param filters object
 * @param pageNo number
 * @param appId string
 * @param sorts object
 * @returns {Promise<*>}
 */
export const getDataStoreHL7Messages = ({
    filters = {},
    pageNo = 1,
    sorts = { createdAt: -1 },
    limit = 10,
    finallyCallback,
    successCallback,
    errorCallback,
    appInstanceId,
    appId,
} = {}) =>
    sendGetRequest({
        url: `/dataStoreDocument/${appId}/${appInstanceId}/hl7/messages`,
        data: {
            pageNo,
            limit,
            filters: JSON.stringify(parseHL7MessageDateFilterValuesForRequest(filters)),
            sorts: JSON.stringify(normalizeHL7MessageSortColumnsForRequest(sorts)),
        },
        ...processResponse({
            ignoreErrorCodes,
            successCallback,
            finallyCallback,
            errorCallback,
        }),
    });

export const formatHL7MessageDateFilterValue = (dateObj) => dateObj?.format?.('MM/DD/YYYY hh:mm A') || null;

/**
 * Sanitize HL7 message filter values
 * @params {object} values Filter values
 * @property {string} values.messageTriggerEvent Message trigger event
 * @property {string} values.patientIdentifier Patient identifier
 * @property {string} values.attendingProvider Attending provider
 * @property {string} values.processedAtStart Processed at start
 * @property {string} values.messageDateStart Message date start
 * @property {string} values.receivedAtStart Received at start
 * @property {string} values.messageDateEnd Message date end
 * @property {string} values.processedAtEnd Processed at end
 * @property {string} values.receivedAtEnd Received at end
 * @property {string} values.visitNumber Visit number
 * @property {string} values.messageCode Message code
 * @property {string} values.messageId Message ID
 * @property {string} values.controlId Control ID
 * @property {string} values.processed Processed
 */
export const sanitizeHL7MessageFilterValues = (values) => {
    const filters = {};

    for (const o in values) {
        const val = values?.[o];

        if (val && val?.format) {
            filters[o] = formatHL7MessageDateFilterValue(val);
        } else if (typeof val !== 'object') {
            const filterValue = `${val || ''}`.trim();
            if (filterValue !== '') {
                filters[o] = filterValue;
            }
        }
    }
    return filters;
};
