export const integrationworkflowstyle = {
    //index.js
    integrationworkflowtopbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#575757',
        padding: 10,
        marginTop: '4rem',
        color: '#fff',
        height: 40,
        '& .MuiTypography-root': {
            fontSize: '1rem',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiButton-root': { color: '#fff' },
    },
    seconddiv: {
        display: 'flex',
        '& .Mui-disabled': {
            background: '#c7c7c7!important',
        },
        '& .MuiTypography-root': {
            fontSize: '1rem',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            '& img': {
                paddingLeft: 10,
                cursor: 'pointer',
                color: '#3a70d4',
            },
        },
    },
    bottomDiv: {
        width: 10,
        height: '88vh',
        position: 'fixed',
        right: 0,
        background: '#333333',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9,
        '& .MuiButton-root': {
            background: '#333333',
            borderRadius: '0px 0px 5px 5px',
            transform: 'rotate(90deg)',
            width: '108px',
            height: '12px',
            right: 9,
        },
    },
    RightdrawerbtnDiv: {
        right: '400px',
        width: '11px',
        height: '86vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 0,
        position: 'absolute',
        '& .MuiButton-root': {
            right: '-1px',
            width: '108px',
            height: '15px',
            transform: 'rotate(90deg)',
            background: '#333',
            borderRadius: '0px 0px 5px 5px',
            boxShadow: '3px 0 #352d2d, 0 0.2em 0 0.2em rgb(56 51 51 / 87%)',
            zIndex: 9,
        },
    },
    bottomdrawer: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiPaper-root': {
            background: '#333333',
            overflow: 'hidden',
        },
    },
    //innersection.js
    innersectionroot: {
        '& .css-26l3qy-menu': {
            marginTop: 0,
            position: 'relative',

            paddingTop: 0,
            paddingBottom: 0,
            background: '#4f4f4f',
            color: '#fff',
            fontSize: '0.815rem',
            fontWeight: 400,
            borderRadius: '0 0 5px 5px',
            '& div::-webkit-scrollbar': {
                width: 0,
            },

            '& div': {
                borderBottom: '1px solid #828282',
            },
            '& div:last-child': {
                borderBottom: 0,
            },
            '& .css-1n7v3ny-option': {
                backgroundColor: 'transparent',
            },
        },
        '& .MuiTypography-root': {
            textTransform: 'capitalize',
        },
        '& .css-1okebmr-indicatorSeparator': {
            display: 'none',
        },
        '& .css-tj5bde-Svg': {
            color: '#3a70d4',
        },
        '& .css-tlfecz-indicatorContainer:last-child': {
            paddingRight: 4,
        },
        '& .css-tlfecz-indicatorContainer': {
            padding: 0,
        },
        '& .css-1gtu0rj-indicatorContainer': {
            padding: 0,
        },
        '& .css-1pahdxg-control': {
            backgroundColor: 'transparent',
        },

        padding: '20px 10px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    maindiv: {
        borderBottom: '1px solid #797979',
        borderLeft: '1px solid #797979',
        borderRight: '1px solid #797979',
        paddingBottom: 10,
        borderRadius: 4,
    },
    container: {
        display: 'flex',
        paddingRight: '1rem',
        '& .MuiGrid-container': {
            alignItems: 'center',
        },
        alignItems: 'center',
        background: '#fff',
        borderRadius: '4px 4px 0 0',
        padding: '10px 0',
        '& .MuiInputBase-input': {
            padding: 10,
            fontSize: '0.813rem',
            fontWeight: '400',
            color: '#fff',
            background: '#4F4F4F',
            borderRadius: 5,
            '&::placeholder': {
                opacity: 1,
            },
        },
        '& .MuiGrid-item:first-child': {
            background: '#3a70d4',
            '& .MuiTypography-root': {
                color: '#fff',
                fontSize: '1.25rem',
                fontWeight: 600,
            },
            borderRadius: '0 5px 5px 0',
            padding: '2px 10px',
        },
        '& .MuiGrid-item': {
            padding: '0 10px',
            fontSize: '0.813rem',
            fontWeight: 400,
            color: '#8B9197',
            '& .css-1wa3eu0-placeholder': {
                color: '#000000',
            },
            '& .css-4ljt47-MenuList': {
                paddingTop: 0,
                paddingBottom: 0,
                background: '#fff',
                color: '#000000',
                fontSize: '0.815rem',
                fontWeight: 500,
                borderRadius: '0 0 5px 5px',
                '& div:hover': {
                    backgroundColor: '#3a70d4',
                    cursor: 'pointer',
                    color: '#fff',
                },
                '& div': {
                    borderBottom: '1px solid #3a70d4',
                },
                '& div:last-child': {
                    borderBottom: 0,
                },
                '& .css-1n7v3ny-option': {
                    backgroundColor: 'transparent',
                },
            },
            '& .MuiTypography-root': {
                fontSize: '1rem',
                fontWeight: 600,
                color: 'black',
            },
        },
    },
    firstdiv: {
        '& .MuiTypography-root': {
            padding: '10px 0',
            fontSize: '0.813rem',
            color: '#fff',
            fontWeight: 600,
        },
        '& .MuiInputBase-root:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3a70d4',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: 12,
            color: '#fff',
            fontSize: '0.813rem',
            fontWeight: 400,
            borderColor: 'red',
            background: '#4f4f4fed',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#828282',
        },
        '& .css-1wa3eu0-placeholder': {
            color: '#fff',
        },
        '& .css-4ljt47-MenuList': {
            paddingTop: 0,
            paddingBottom: 0,
            background: '#4f4f4f',
            color: '#fff',
            fontSize: '0.815rem',
            fontWeight: 400,
            borderRadius: '0 0 5px 5px',
            '&::-webkit-scrollbar': {
                width: 0,
            },
            '& div:hover': {
                backgroundColor: '#3a70d4',
                cursor: 'pointer',
            },
            '& div': {
                borderBottom: '1px solid #828282',
            },
            '& div:last-child': {
                borderBottom: 0,
            },
            '& .css-1n7v3ny-option': {
                backgroundColor: 'transparent',
            },
        },
        '& .css-jz0aj3-MenuList': {
            paddingTop: 0,
            paddingBottom: 0,
            background: '#4f4f4f',
            color: '#fff',
            fontSize: '0.815rem',
            fontWeight: 400,
            borderRadius: '0 0 5px 5px',
            '&::-webkit-scrollbar': {
                width: 0,
            },
            '& div:hover': {
                backgroundColor: '#3a70d4',
                cursor: 'pointer',
            },
            '& div': {
                borderBottom: '1px solid #828282',
            },
            '& div:last-child': {
                borderBottom: 0,
            },
            '& .css-1n7v3ny-option': {
                backgroundColor: 'transparent',
            },
        },

        '& .css-2b097c-container': {
            fontSize: '0.813rem',
            fontWeight: 400,
            backgroundColor: '#4f4f4f',
            '& .css-yk16xz-control': {
                borderColor: '#8B9197',
                background: '#4f4f4f',
                '&:hover': {
                    borderColor: '#3a70d4',
                },
            },
            '& .css-1uccc91-singleValue': {
                color: '#fff',
            },
        },
    },

    innersectionseconddiv: {
        '& .MuiTypography-root': {
            fontSize: '0.813rem',
            fontWeight: 600,
            padding: '10px 0',
            color: '#fff',
        },

        '& div .MuiTypography-root': {
            fontWeight: 400,
            padding: 0,
        },
    },
    seconddivcontainer: {
        '& .MuiTypography-root': {
            fontWeight: '400!important',
            padding: '5px 0!important',
        },
        background: '#3a70d4',
        borderRadius: '5px 5px 0px 0',
        padding: '0 0 0 10px',
    },
    seconddivcontainer1: {
        '& .MuiInputBase-input': {
            padding: '0 12px',
            color: '#fff',
            fontSize: '0.813rem',
            fontWeight: 400,
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1rem',
            padding: '2px 0',
        },
        '& .MuiTypography-root': {
            textTransform: 'none',
            padding: '2px 0!important',
        },
        '& .MuiGrid-item:last-child': {
            display: 'flex',
            borderTop: 0,
            alignItems: 'center',
            backgroundColor: '#606060',
            border: '1px solid #3a70d4',
        },
        background: '#4F4F4F',
        border: '1px solid #848484',
        borderTop: 0,
        padding: '0 0 0 10px',
    },
    boxdiv: {
        background: '#4F4F4F',
        minHeight: '84%',
        border: '1px solid #848484',
        borderTop: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiTypography-root': {
            textTransform: 'none',
            textAlign: 'center',
        },
    },
    thirddiv: {
        '& .MuiTypography-root': {
            fontSize: '0.813rem',
            fontWeight: 600,
            padding: '10px 0',
            color: '#fff',
        },
    },
    thirddivbox: {
        '& .MuiTypography-root': {
            fontWeight: 400,
            padding: 0,
            textTransform: 'none',
        },
        minHeight: '84%',
        border: '1px solid #828282',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innersectiondialogbox: {
        '& .MuiDialog-paper': {
            borderRadius: 10,

            minWidth: '30%',
            minHeight: '-webkit-fill-available',
            '& .MuiGrid-container': {
                '& .MuiTypography-root': {
                    fontSize: '0.75rem!important',
                    paddingRight: '2rem',
                    color: '#000000',
                    opacity: 1,
                },
                '& .MuiSvgIcon-root': {
                    fontSize: '1.2rem',
                },
                '& .MuiGrid-item': {
                    display: 'flex',
                    margin: '10px 0',
                    padding: '5px 10px',
                    border: '1px solid #d7dcea',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: 5,
                },
            },
            '& .MuiDialogContent-root': {
                '& .MuiTypography-root:first-child': {
                    fontSize: '0.688rem',
                    color: '#000000',
                    opacity: 1,
                },
            },
        },
    },

    popupheaderdiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiSvgIcon-root': { fontSize: '1rem' },
        background: '#575757',
        color: '#fff',
        padding: '8px 24px',
        '& .MuiTypography-root': {
            fontSize: '0.75rem',
            fontWeight: '500',
        },
    },
    addnewstep: {
        padding: '0 10px',
        position: 'relative',
        '& hr': {
            border: 0,
            borderBottom: '1px solid #828282',
        },
        '& div': {
            '& .MuiButtonBase-root': {
                background: '#333333',
                color: '#fff',
                fontSize: '0.815rem',
                fontWeight: 400,
                textTransform: 'capitalize',
            },
            width: '100%',
            textAlign: 'center',
            top: -15,
            bottom: 0,
            position: 'absolute',
        },
    },
    innersectionseconddilaogbox: {
        '& .MuiDialogActions-root': {
            borderTop: '1px solid #c4c4c4',
            justifyContent: 'space-between',

            '& .MuiButtonBase-root': {
                fontSize: '0.875rem',
                fontWeight: 700,
                textTransform: 'capitalize',
                background: '#bdbdbd',
                border: '1px solid #fff',
                color: '#fff',
                padding: '5px 20px',
                borderRadius: 5,
                '&:hover': {
                    background: '#fff',
                    border: '1px solid #bdbdbd',
                    color: '#bdbdbd',
                },
            },
        },
        '& .MuiPaper-rounded': { borderRadius: 10 },
        '& .MuiDialogTitle-root': {
            background: '#4f4f4f',
            padding: '10px 24px',
            '& h2': {
                fontSize: '0.75rem',
                fontWeight: 600,
                color: '#fff',
            },
        },
    },
    datedialogbox: {
        '& .MuiDialog-paper': {
            borderRadius: 10,
            maxWidth: 'fit-content',
            '& .MuiGrid-container': {
                '& .MuiTypography-root': {
                    fontSize: '0.75rem!important',
                    paddingRight: '2rem',
                    color: '#000000',
                    opacity: 1,
                },
                '& .MuiSvgIcon-root': {
                    fontSize: '1.2rem',
                },
                '& .MuiGrid-item': {
                    display: 'flex',
                    margin: '10px 0',
                    padding: '5px 10px',
                    border: '1px solid #d7dcea',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: 5,
                },
            },
            '& .MuiDialogContent-root': {
                '& .MuiTypography-root:first-child': {
                    fontSize: '0.688rem',
                    color: '#000000',
                    opacity: 1,
                },
            },
        },
    },
    divborder: {
        padding: '1rem 2rem',
    },
    headingtextdiv: {
        color: '#fff',
        '& .MuiTypography-root': {
            fontSize: '0.813rem',
            fontWeight: '600',
            marginBottom: 10,
        },
        '& .MuiInputBase-input': {
            padding: 10,
            borderRadius: 8,
            background: '#fff',
            fontSize: '0.875rem',
            color: '#000',
            fontWeight: 700,
            '&:-webkit-autofill': {
                transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
                transitionDelay: 'background-color 50000s, color 50000s',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d7dcea',
        },
    },
    griddiv: {
        width: '100%',
        maxWidth: 370,
        color: '#fff',
        marginTop: 5,
        '& .MuiButtonBase-root': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            color: '#3A70D4',
        },
        '& .MuiTypography-root': {
            fontSize: '0.813rem',
            fontWeight: '600',
        },
        '& .MuiInputBase-input': {
            padding: 10,
            borderRadius: 5,
            background: '#fff',
            fontSize: '0.75rem',
            color: '#000',
            // fontWeight: 700,
        },
        '& .MuiInputBase-input::placeholder': { color: '#8B9197' },
    },

    checkboxdiv: {
        marginTop: 5,
        '& .MuiFormControlLabel-label': {
            color: '#fff',
            fontSize: '0.813rem',
            fontWeight: '400',
            marginRight: '2.5rem',
        },
    },
    //IntegrationStepView.js
    contentsectionmaindiv: {
        display: 'grid',
        cursor: 'pointer',
        marginBottom: 10,
        alignItems: 'center',
        color: '#fff',
    },
    mainfirstdiv: {
        transition: 'ease-in',
        background: '#3a70d4',
        padding: '15px 6px',
        paddingRight: 10,
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            fontSize: '1rem',
        },
        '& .MuiTypography-body1': {
            fontSize: '0.625rem',
            padding: '0 5px',
        },
    },

    drawer: {
        width: 140,
        flexShrink: 0,
        '& .MuiList-root': {
            height: 'inherit',
            paddingTop: 0,
            marginBottom: 10,
        },
        '& .MuiListItem-button': {
            cursor: 'grab',
            minHeight: 50,
            borderLeft: '5px solid #6198FF',
            borderRadius: 2,
            background: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 0,
            paddingRight: 0,
            marginBottom: 10,
            paddingLeft: 0,
            '& img': {
                width: '100%',
            },
        },
        '& .MuiSvgIcon-root': {
            color: '#6198FF',
            width: '-webkit-fill-available',
        },
    },
    drawerPaper: {
        width: 200,
        top: 'auto',
        background: '#575757',
        boxShadow: '0px 4px 5px rgb(0 0 0 / 15%)',
        '&::-webkit-scrollbar': {
            width: 1,
        },
    },
    avatarimage: {
        width: 24,
        height: 24,
        backgroundSize: 'contain !important',
        objectFit: 'contain !important',
        '& .MuiAvatar-img': {
            backgroundSize: 'contain !important',
            objectFit: 'contain !important',
            minWidth: '36px',
        },
    },
    imgmaindiv: {
        zIndex: 1,
        display: 'flex',
        '& div:first-child': {
            background: '#6198FF',
            padding: 10,
            borderRadius: '10px 0 0 10px',
        },
        '& div:last-child': {
            background: '#6198FF',
            padding: 10,
            borderRadius: '0 10px 10px 0',
        },
    },
    imgdiv: {
        display: 'inline-flex',
        background: '#3a70d4',
        padding: '35px 20px',
    },
    boxStyling: {
        background: '#fff',
        zIndex: 1,
        borderRadius: 5,
        minWidth: 200,
        width: 200,
        border: '2px solid #3A70D4',
        textAlign: 'center',
        cursor: 'pointer',

        '& div:last-child': {
            height: 53.5,
            display: 'flex',
            justifyContent: 'center',
        },
        '& div:first-child': {
            display: 'flex',
            borderBottom: '1px solid #828282',
            padding: 10,
            paddingLeft: 5,
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
                color: '#6198ff',
            },
            '& p': {
                fontSize: '0.75rem',
                fontWeight: '700',
            },
        },
    },

    listdiv: {
        margin: 8,
    },
    stepStyling: {
        background: '#575757',
        zIndex: 1,
        borderRadius: 10,
        minWidth: 200,
        width: 200,
        border: '3px solid #6198ff',
        cursor: 'pointer',
        '& div': {
            display: 'flex',
            justifyContent: 'space-between',
            color: '#fff',
            borderBottom: '1px solid #828282',
            padding: '5px 10px',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
                color: '#fff',
                cursor: 'pointer',
            },
            '& p': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textTransform: 'uppercase',
                fontSize: '0.563rem',
                fontWeight: '600',
            },
        },
    },
    colorCircle: {
        zIndex: 999,
        '& path:first-child': {
            fill: '#fff',
            opacity: 1,
        },
        '& path:last-child': {
            fill: '#6198ff',
            opacity: 1,
        },
        fontSize: '0.7rem',
    },

    contentsectiondialogbox: {
        '& .MuiDialog-paper': {
            padding: '40px 50px',
            borderRadius: 20,
            '& .MuiTypography-root:first-child': {
                fontSize: '1rem',
                fontWeight: 700,
                paddingBottom: '1rem',
                color: '#000',
            },
            '& .MuiTypography-root': {
                fontSize: '0.875rem',
                fontWeight: 400,
                color: '#828282',
                paddingBottom: '0.5rem',
            },
            '& .MuiOutlinedInput-input': {
                background: '#4f4f4f',
                borderRadius: 5,
                padding: 14,
                fontSize: '0.75rem',
                fontWeight: 400,
                color: '#fff',
                marginBottom: '1rem',
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '& .MuiOutlinedInput-notchedOutline': { border: 0 },
            '& .MuiDialogActions-root': {
                justifyContent: 'normal',
                '& .MuiButtonBase-root': {
                    width: '100%',
                    textTransform: 'capitalize',
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    color: '#fff',
                    borderRadius: 5,
                },

                '& .MuiButtonBase-root:last-child': {
                    marginLeft: '3rem',
                },
            },
        },
    },
    //bottomdrawer.js
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    bottomdrawerbottomDiv: {
        borderTop: '1px solid #797979',
        width: '100%',
        background: '#575757',
        display: 'flex',
        justifyContent: 'center',
        color: '#fff',
        '& .MuiButton-root': {
            background: '#333333',
            borderRadius: '0px 0px 5px 5px',
        },
    },
    rightawerrightDiv: {
        width: 10,
        height: '83vh',
        position: 'absolute',
        left: 0,
        background: '#333333',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiButton-root': {
            background: '#333333',
            borderRadius: '0px 0px 5px 5px',
            transform: 'rotate(90deg)',
            width: '108px',
            height: '12px',
            right: 9,
        },
    },
    bottomheader: {
        borderBottom: '1px solid #797979',
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        marginTop: -0.4,
        justifyContent: 'space-between',
        padding: 10,
        paddingTop: 0,
        paddingLeft: 20,
        '& .MuiTypography-root': {
            fontSize: '1.125rem',
            fontWeight: '400',
            color: '#fff',
        },
        '& .MuiButton-root:first-child': {
            padding: '6px 10px',
            color: '#fff',
            fontSize: '0.875rem',
            fontWeight: 700,
            textTransform: 'capitalize',
            backgroundColor: '#3a70d4',
        },
        '& .MuiButton-root:nth-child(2)': {
            padding: 11,
            marginLeft: 10,
            minWidth: 'fit-content',
            textTransform: 'capitalize',
            backgroundColor: '#3a70d4',
        },
    },
    innerdiv: {
        '&::-webkit-scrollbar': {
            width: 0,
        },
        height: 'inherit',
        overflow: 'auto',
        paddingBottom: '6rem',
    },
    bottomdrawerdilaogbox: {
        '& .MuiDialogActions-root': {
            borderTop: '1px solid #c4c4c4',
            justifyContent: 'space-between',

            '& .MuiButtonBase-root': {
                fontSize: '0.875rem',
                fontWeight: 700,
                textTransform: 'capitalize',
                background: '#bdbdbd',
                border: '1px solid #fff',
                color: '#fff',
                padding: '5px 20px',
                borderRadius: 5,
                '&:hover': {
                    background: '#fff',
                    border: '1px solid #bdbdbd',
                    color: '#bdbdbd',
                },
            },
        },
        '& .MuiPaper-rounded': { borderRadius: 10 },
        '& .MuiDialogTitle-root': {
            background: '#4f4f4f',
            padding: '10px 24px',
            '& h2': {
                fontSize: '0.75rem',
                fontWeight: 600,
                color: '#fff',
            },
        },
    },
    addstepdiv: {
        position: 'relative',
        '& hr': {
            width: '100%',
            border: 0,
            borderBottom: '1px dashed #828282',
        },
        '& div': {
            '& .MuiButtonBase-root': {
                background: '#333333',
                color: '#fff',
                fontSize: '0.815rem',
                fontWeight: 400,
                textTransform: 'capitalize',
            },
            width: '100%',
            textAlign: 'center',
            position: 'absolute',
        },
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    //Right drawer
    newdrawer: {
        flexShrink: 0,
        marginTop: '-1.5px',
        '& .MuiPaper-root,.MuiPaper,.MuiDrawer-root': {
            width: 400,
            top: 'auto',
            background: '#fff',
            zIndex: 90,
            overflowY: 'auto',
            paddingBottom: 20,
            boxShadow: '0px 3px #fff, -0.2em 0 0.5em #23202029',
            '&::-webkit-scrollbar': {
                width: 1,
            },
            '&::before': {
                content: '',
                position: 'absolute',
            },
        },
    },
    Rightdrawheader: {
        background: '#3E3E3E',
        display: 'flex',
        alignItems: 'center',
        marginTop: 0.5,
        justifyContent: 'space-between',
        padding: '5px 0',
        paddingTop: 4,
        paddingLeft: 20,
        '& .MuiTypography-root': {
            fontSize: '0.813rem',
            fontWeight: 'normal',
            color: '#fff',
        },
        '& .MuiButton-root:nth-child(2)': {
            backgroundColor: '#4C4C4D',
            padding: '4px 0 4px 0',
        },
    },
    rightDrawerDiv: {
        minWidth: '465px',
        '& .MuiListItem-root': {
            padding: '10px',
            background: '#FFFFFF',
            boxSizing: 'border-box',
            borderRadius: '5px',
            '&.MuiListItemSecondaryAction-root': {
                PointerEvent: 'none',
            },
        },
        '& .MuiButtonBase-root:hover': {
            background: '#FFFFFF',
            boxSizing: 'border-box',
            borderRadius: '5px',
        },
        '& .MuiListItemText-root': {
            '& .MuiTypography-root:nth-child(1)': {
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '13px',
                color: '#000000',
                paddingBottom: 0,
            },
            '& .MuiTypography-root:nth-child(2)': {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '13px',
                color: '#4F4F4F',
            },
        },
    },
    rightDrawerDiv2: {
        '& .MuiListItem-root': {
            padding: '10px',
            marginBottom: 5,
            background: '#333333',
            border: '2px solid #828282',
            boxSizing: 'border-box',
            borderRadius: '5px',
            '&.MuiListItemSecondaryAction-root': {
                PointerEvent: 'none',
                '& .MuiButton-text': {
                    padding: '2px',
                },
            },
        },
        '& .MuiListItemText-root': {
            '& .MuiTypography-root:nth-child(1)': {
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '13px',
                color: '#fff',
                paddingBottom: 0,
            },
            '& .MuiTypography-root:nth-child(2)': {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '13px',
                color: '#fff',
            },
        },
    },
    rightdrawetext: {
        overflow: 'auto',
        height: '72vh',
        '&::-webkit-scrollbar': {
            width: 1,
        },
        marginTop: 10,
        '&.MuiGrid-grid-sm-12': {
            flexBasis: 0,
        },
        '& .MuiTypography-root:nth-child(1)': {
            fontStyle: 'normal',
            // fontWeight: 600,
            fontSize: '13px',
            textTransform: 'capitalize',
            color: '#FFFFFF',
        },
        '& .MuiTypography-root:nth-child(2)': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            alignItems: 'center',
            color: '#9F9F9F',
        },
    },
    Drawercheckboxdiv: {
        '& .MuiTypography-root': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.813rem',
            padding: '10px 0 3px 0',
        },
        '& .MuiFormControlLabel-label': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            marginRight: '3px',
            marginTop: 7,
        },
    },
    drawerbottmSection: {
        '& .MuiTypography-root:nth-child(1)': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.875rem',
            textTransform: 'capitalize',
            color: '#FFFFFF',
            paddingBottom: 10,
        },
        '& .MuiTypography-root:nth-child(2)': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.813rem',
            alignItems: 'center',
            color: '#fff',
        },
    },
    rightDrawerParameter: {
        '& .MuiButtonBase-root': {
            '& .MuiSvgIcon-root': { color: '#fff!important' },
        },
        '& .MuiInputBase-multiline': { padding: 0 },
        '& .MuiOutlinedInput-multiline': { padding: 0 },
        '& .MuiIconButton-edgeEnd': {
            '& .MuiSvgIcon-root': {
                color: '#3A70D4',
            },
        },
        fontWeight: 'normal',
        fontSize: '0.813rem',
        '& .select__value-container': {
            padding: '0 9px',
            marginBottom: 4,
        },
        '& .select__input': { color: '#000!important' },
        '& .select__control': {
            backgroundColor: '#fff!important',
            height: '25px!important',
        },
        '& .select__menu': {
            marginTop: 0,
            backgroundColor: '#fff!important',
        },
        '& .MuiTypography-root': {
            textTransform: 'capitalize',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            color: '#FFFFFF',
            justifyContent: 'start',
            paddingBottom: 10,
        },
        '& .select__indicator-separator': {
            display: 'none!important',
        },
        '& svg': {
            color: '#3A70D4!important',
            marginTop: -4,
        },
        '& .select__dropdown-indicator:last-child': {
            paddingRight: 14,
        },
        '& .select__dropdown-indicator': {
            padding: '13px 7px!important',
        },
        '& .css-1gtu0rj-indicatorContainer': {
            padding: '13px 7px!important',
        },
        '& .css-1pahdxg-control': {
            backgroundColor: '#484747!important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#828282',
        },
        '& .select__placeholder': {
            color: '#8B9197!important',
            fontWeight: 'normal',
            fontSize: '0.813rem',
        },
        '& .select__menu-list': {
            paddingTop: 0,
            paddingBottom: 0,
            background: '#fff',
            color: '#000',
            fontSize: '0.815rem',
            fontWeight: 400,
            borderRadius: '0 0 5px 5px',
            '& div:hover': {
                backgroundColor: '#3a70d4',
                cursor: 'pointer',
            },
            '& div': {
                borderBottom: '1px solid #828282',
            },
            '& div:last-child': {
                borderBottom: 0,
            },
            '& .select__option': {
                backgroundColor: '#fff',
            },
        },
        '& .css-2b097c-container': {
            fontSize: '0.813rem',
            fontWeight: 400,
            backgroundColor: '#4f4f4f',
            '& .select__control': {
                borderColor: '#828282!important',
                background: '#fff',
                '&:hover': {
                    borderColor: '#3a70d4!important',
                },
            },
            '& .select__control--is-focused': {
                borderColor: '#828282!important',
                background: '#fff!important',
                '&:hover': {
                    borderColor: '#3a70d4!important',
                },
            },
            '& .select__control--menu-is-open': {
                borderColor: '#828282!important',
                background: '#fff!important',
                '&:hover': {
                    borderColor: '#3a70d4!important',
                },
            },
            '& .css-1uccc91-singleValue': {
                color: '#000',
            },
        },
        '& .MuiInputBase-input': {
            color: '#000',
            padding: '10px',
            fontSize: '0.75rem',
            background: '#fff',
            fontWeight: 700,
            borderRadius: '5px',
            height: 18.5,
        },
    },
    rightDrawerParamValue: {
        background: '#fff',
        paddingRight: '5px',
        '& .MuiInputAdornment-root': {
            '& .MuiButtonBase-root': {
                borderRadius: 'unset',
                padding: '0.5rem',
                minWidth: 'fit-content',
            },
        },
    },
    rightDrawerSelect: {
        '& .MuiButtonBase-root': {
            '& .MuiSvgIcon-root': { color: '#fff!important' },
        },
        '& .MuiInputBase-multiline': { padding: 0 },
        '& .MuiOutlinedInput-multiline': { padding: 0 },
        '& .MuiIconButton-edgeEnd': {
            '& .MuiSvgIcon-root': {
                color: '#3A70D4',
            },
        },
        fontWeight: 'normal',
        fontSize: '0.813rem',
        '& .select__value-container': {
            padding: '0 9px',
            marginBottom: 4,
        },
        '& .select__input': { color: '#000!important' },
        '& .select__control': {
            backgroundColor: '#fff!important',
            height: '25px!important',
        },
        '& .select__menu': {
            marginTop: 0,
            backgroundColor: '#fff!important',
        },
        '& .MuiTypography-root': {
            textTransform: 'capitalize',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            color: '#FFFFFF',
            justifyContent: 'start',
            paddingBottom: 10,
        },
        '& .select__indicator-separator': {
            display: 'none!important',
        },
        '& svg': {
            color: '#3A70D4!important',
            // marginTop: -4,
        },
        '& .stepTooltipIcon': {
            // color: '#fff !important',
        },
        '& .select__dropdown-indicator:last-child': {
            paddingRight: 14,
        },
        '& .select__dropdown-indicator': {
            padding: '13px 7px!important',
        },
        '& .css-1gtu0rj-indicatorContainer': {
            padding: '13px 7px!important',
        },
        '& .css-1pahdxg-control': {
            backgroundColor: '#484747!important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#828282',
        },
        '& .select__placeholder': {
            color: '#8B9197!important',
            fontWeight: 'normal',
            fontSize: '0.813rem',
        },
        '& .select__menu-list': {
            paddingTop: 0,
            paddingBottom: 0,
            background: '#fff',
            color: '#000',
            fontSize: '0.815rem',
            fontWeight: 400,
            borderRadius: '0 0 5px 5px',
            '& div:hover': {
                backgroundColor: '#3a70d4',
                cursor: 'pointer',
            },
            '& div': {
                borderBottom: '1px solid #828282',
            },
            '& div:last-child': {
                borderBottom: 0,
            },
            '& .select__option': {
                backgroundColor: '#fff',
            },
        },
        '& .css-2b097c-container': {
            fontSize: '0.813rem',
            fontWeight: 400,
            backgroundColor: '#4f4f4f',
            '& .select__control': {
                borderColor: '#828282!important',
                background: '#fff',
                '&:hover': {
                    borderColor: '#3a70d4!important',
                },
            },
            '& .select__control--is-focused': {
                borderColor: '#828282!important',
                background: '#fff!important',
                '&:hover': {
                    borderColor: '#3a70d4!important',
                },
            },
            '& .select__control--menu-is-open': {
                borderColor: '#828282!important',
                background: '#fff!important',
                '&:hover': {
                    borderColor: '#3a70d4!important',
                },
            },
            '& .css-1uccc91-singleValue': {
                color: '#000',
            },
        },
    },
    stepDrawtxt: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1rem',
        color: '#fff',
        marginTop: 15,
        paddingBottom: 9,
    },
    stepContainer: {
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
        },
        '& .MuiTypography-root': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.875rem',
            margin: '5px 0',
            color: '#fff',
        },
        '& .MuiGrid-grid-sm-12:nth-child(3)': {
            margin: '5px 0',
            '& .MuiTypography-root': {
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
            },
        },
        '& .MuiGrid-grid-sm-12:nth-child(4)': {
            margin: '5px 0',
            '& .MuiTypography-root': {
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
            },
        },
    },
    bottombtnDiv: {
        position: 'absolute',
        bottom: 110,
        '& .MuiButton-root:nth-child(1)': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.813rem',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: 4,
            background: '#626262',
            '&.MuiButton-root:hover': {
                background: '#fff',
                color: '#3A70D4',
            },
        },
        '& .MuiButton-root:nth-child(2)': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.875rem',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: 4,
            background: '#3A70D4',
            '&.MuiButton-root:hover': {
                background: '#fff',
                color: '#3A70D4',
            },
        },
    },
    rightdrawerContainer: {
        marginTop: 10,
        '&.MuiGrid-grid-sm-12': {
            flexBasis: 0,
        },
        '& .MuiTypography-root:nth-child(1)': {
            fontStyle: 'normal',
            // fontWeight: 600,
            fontSize: '13px',
            textTransform: 'capitalize',
            color: '#000',
            paddingBottom: 10,
            paddingTop: 3,
        },
        '& .MuiTypography-root:nth-child(2)': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            alignItems: 'center',
            color: '#9F9F9F',
        },
    },
    rightdrawerstep: {
        marginTop: 10,
        '&.MuiGrid-grid-sm-12': {},
        '& .MuiTypography-root:nth-child(1)': {
            fontStyle: 'normal',
            // fontWeight: 600,
            fontSize: '13px',
            textTransform: 'capitalize',
            color: '#FFFFFF',
            paddingBottom: 10,
            paddingTop: 3,
        },
        '& .MuiTypography-root:nth-child(2)': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            alignItems: 'center',
            color: '#9F9F9F',
        },
    },
    executeManualTrigger: {
        marginTop: 10,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.813rem',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: 4,
        background: '#3A70D4',
        '&.MuiButton-root:hover': {
            background: '#fff',
            color: '#3A70D4',
        },
    },
    rightstepdrawerstep: {
        marginTop: 5,
        '&.MuiGrid-grid-sm-12': {},
        '& .MuiTypography-root:nth-child(1)': {
            fontStyle: 'normal',
            // fontWeight: 600,
            fontSize: '13px',
            textTransform: 'capitalize',
            color: '#000',
            paddingTop: 3,
        },
        '& .MuiTypography-root:nth-child(2)': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            alignItems: 'center',
            color: '#9F9F9F',
        },
        '& .MuiList-root': {
            paddingTop: 3,
            paddingBottom: 4,
        },
    },
    newleftdrawer: {
        flexShrink: 0,
        '& .MuiPaper-root,.MuiPaper,.MuiDrawer-root': {
            width: '225px',
            background: '#333333',
            boxShadow: '0px 3px #fff, -0.2em 0 0.5em rgb(35 32 32 / 87%)',
            '&::-webkit-scrollbar': {
                width: 1,
            },
        },
        '& .MuiButtonBase-root:hover': {
            background: '#FFFFFF',
            border: '2px solid #3A70D4',
            boxSizing: 'border-box',
            borderRadius: '5px',
        },
        '& .MuiTypography-root:nth-child(1)': {
            fontStyle: 'normal',
            // fontWeight: 600,
            fontSize: '13px',
            paddingTop: 3,
        },
    },
    leftDiv: {
        width: 10,
        height: '88vh',
        position: 'fixed',
        left: 0,
        background: '#333333',
        display: 'flex',
        alignItems: 'center',
        zIndex: 9,
        justifyContent: 'center',
        '& .MuiButton-root': {
            background: '#333333',
            borderRadius: '5px 5px 0px 0px',
            transform: 'rotate(90deg)',
            width: '108px',
            height: '12px',
            left: 9,
            zIndex: 99999,
        },
    },
    dragIcon: {
        top: '58%',
        right: '10px',
        position: 'absolute',
        width: 'auto',
        borderRadius: 5,
        fontSize: '1.2rem',
    },
    stepsStyling: {
        zIndex: 1,
        borderRadius: 5,
        minWidth: 200,
        width: 200,
        border: '2px solid #3A70D4',

        '& div:last-child': {
            height: 53.5,
            display: 'flex',
            justifyContent: 'center',
            padding: 5,
        },
        '& div:first-child': {
            display: 'flex',
            borderBottom: '1px solid #828282',
            padding: 10,
            paddingLeft: 5,
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
                color: '#6198ff',
            },
            '& p': {
                fontSize: '0.75rem',
                fontWeight: '700',
            },
        },
    },
    drawerdatepicker: {
        height: '38px',
        borderRadius: '5px',
        paddingLeft: 8,
    },
    errorMessage: {
        color: '#FF0000',
    },
    //NewFlowDropZone.js
    stepContainerDiv: {
        width: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'width 100ms',
    },
    stepMainDiv: {
        borderRadius: 10,
        justifyContent: 'center',
        width: 'auto',
        alignItems: 'center',
        marginBottom: -8,
        border: 0,
        background: null,
    },
    triggerStepMainDiv: {
        justifyContent: 'space-between',
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 5,
        paddingBottom: 5,
        margin: '0 0px -8px 0',
    },
    triggerStepTitle: {
        display: 'flex',
        alignItems: 'center',
        transition: 'all 300ms',
    },
    triggerIcon: {
        minWidth: 'auto',
        padding: 0,
        cursor: 'pointer',
    },
    triggerDescriptionMainDiv: {
        width: '100%',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 0,
        paddingRight: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    triggerDescriptionMainText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 13,
        display: 'flex',
        alignItems: 'left',
        color: '#000000',
        transition: 'all 300ms',
    },
    triggerStepLine: {
        minHeight: 'inherit',
        borderLeft: '2px dashed #6198ff',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '10vh',
        width: '6px',
        marginLeft: 4,
    },
    triggerPlusButton: {
        position: 'absolute',
        // backgroundColor: '#484747',
        color: '#fff',
        marginLeft: -4,
        fontSize: '1rem',
        minWidth: 'auto',
        padding: 5,
    },
    triggerPlusIcon: {
        fontSize: '1.2rem',
        background: '#575757',
        borderRadius: '50%',
    },
    stepDropSpace: {
        position: 'absolute',
        color: '#828282',
        fontSize: '0.813rem',
        fontWeight: '600',
        top: '50%',
        paddingLeft: '3rem',
    },
    stepLine: {
        borderLeft: '2px dashed #6198ff',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '10vh',
        width: '3px',
    },
    stepPlaceholder: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    stepPlusButton: {
        position: 'absolute',
        // backgroundColor: '#484747',
        color: '#fff',
        marginLeft: -3,
        fontSize: '1rem',
        minWidth: 'auto',
        padding: 5,
    },
    zoomControlDrawer: {
        position: 'sticky',
        float: 'inherit',
        '& .MuiIconButton-root': {
            backgroundColor: 'rgb(76, 76, 77)',
            borderRadius: 3,
            padding: 5,
        },
    },
    zoomContainer: {
        overflow: 'overflow:hidden',
        height: '72vh',
        '&:hover': {
            overflowY: 'auto',
        },
        '&::-webkit-scrollbar': {
            width: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#575757',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            background: '#575757',
        },
        //marginTop: 10,
        '&.MuiGrid-grid-sm-12': {
            flexBasis: 0,
        },
    },
    zoomTriggerContainer: {
        overflow: 'overflow:hidden',
        height: '72vh',
        '&.MuiGrid-grid-sm-12': {
            flexBasis: 0,
        },
    },
    rightdrawBottomBar: {
        position: 'fixed',
        marginTop: 'calc(100vh - 145px)',
        '& button': {
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    stepsList: {
        display: 'flex',
        alignItems: 'center',
        height: 'inherit',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
        overflow: 'hidden !important',
        '& .react-transform-wrapper': {
            width: '100%',
            height: 'calc(100vh - 145px)',
            overflow: 'scroll',
        },
        '& .react-transform-wrapper::-webkit-scrollbar': {
            width: '0 !important',
            height: 0,
        },
        '& .react-transform-component': {
            display: 'block',
            transform: 'initial',
            width: '100%',
            marginTop: 100,
            height: '100vh',
        },
    },
};
