import SvgIcon from '@mui/material/SvgIcon';

const EllipsisIcon = (props) => {
    return (
        <SvgIcon {...props} width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.44 4.56C0.63 4.56 0 5.22 0 6C0 6.81 0.63 7.44 1.44 7.44C2.22 7.44 2.88 6.81 2.88 6C2.88 5.22 2.22 4.56 1.44 4.56ZM0 1.44C0 2.25 0.63 2.88 1.44 2.88C2.22 2.88 2.88 2.25 2.88 1.44C2.88 0.66 2.22 0 1.44 0C0.63 0 0 0.66 0 1.44ZM0 10.56C0 11.37 0.63 12 1.44 12C2.22 12 2.88 11.37 2.88 10.56C2.88 9.78 2.22 9.12 1.44 9.12C0.63 9.12 0 9.78 0 10.56Z"
                fill="#6198FF"
            />
        </SvgIcon>
    );
};
export default EllipsisIcon;
