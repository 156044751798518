import { getGatewayUrl } from './envUtils';
import { authorizedAxiosInstance } from '../axiosInstances';
import HttpStatusCodes from '../classes/HttpStatusCodes';

const CryptoJS = require('crypto-js');

/**
 * @param organizationId
 * @param value
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDecryptedValue = async (organizationId, value) => {
    return authorizedAxiosInstance
        .request({
            url: getGatewayUrl(`/organization/${organizationId}/decrypt`),
            method: 'post',
            data: { valueToDecrypt: value },
        })
        .then((response) => {
            if (response.status === HttpStatusCodes.SUCCESS) {
                return response.data.decryptedValue;
            }

            return false;
        })
        .catch(() => {
            return false;
        });
};

/**
 * @param {string} organizationId
 * @param {string} value
 * @returns {Promise<string|boolean>}
 */
export const getEncryptedValue = async (organizationId, value) => {
    return authorizedAxiosInstance
        .request({
            url: getGatewayUrl(`/organization/${organizationId}/encrypt`),
            method: 'post',
            data: { valueToEncrypt: value },
        })
        .then((response) => {
            if (response.status === HttpStatusCodes.SUCCESS) {
                return response.data.encryptedValue;
            }

            return false;
        })
        .catch(() => false);
};

/**
 * @param {string} organizationId
 * @param {string} appId
 * @param {string} instanceId
 * @param {string} interfaceId
 * @returns {Promise<string|false>}
 */
export const getStateKey = async (organizationId, appId, instanceId, interfaceId) => {
    return authorizedAxiosInstance
        .request({
            url: getGatewayUrl('/applications/oauthState'),
            method: 'post',
            data: {
                organizationId,
                appId,
                instanceId,
                interfaceId,
            },
        })
        .then((response) => {
            if (response.status === HttpStatusCodes.SUCCESS) {
                return response.data.stateKey;
            }

            return false;
        })
        .catch(() => false);
};

/**
 * @param {string} encryptedStateKey
 * @returns {Promise<string|boolean>}
 */
export const handleStateKey = async (encryptedStateKey) => {
    return authorizedAxiosInstance
        .request({
            url: getGatewayUrl(`/applications/oauthState`),
            method: 'get',
            params: { encryptedStateKey },
        })
        .then((response) => {
            if (response.status === HttpStatusCodes.SUCCESS) {
                return response.data.stateKey;
            }

            return false;
        })
        .catch(() => false);
};

/**
 * Determine if a string is all alphanumeric characters with a list of exception characters.
 *
 * @param {String} string
 * @param {[String]} allowedSpecialCharacters
 * @returns {Boolean}
 */
export const isAlphaNumeric = (string, allowedSpecialCharacters = []) => {
    if (typeof string === 'string') {
        const listOfCharacters = string.split('');
        let numberOfMatchingCharacters = 0;

        for (const character of listOfCharacters) {
            const code = character.charCodeAt(0);

            if (
                (code > 47 && code < 58) || // numeric (0-9)
                (code > 64 && code < 91) || // upper alpha (A-Z)
                (code > 96 && code < 123) || // lower alpha (a-z)
                allowedSpecialCharacters.includes(character)
            ) {
                numberOfMatchingCharacters++;
            }
        }

        return numberOfMatchingCharacters === listOfCharacters.length;
    }

    return false;
};

/**
 * @param {String} string
 * @param {'MD5'|'SHA1'|'SHA224'|'SHA256'|'SHA384'|'SHA512'|'SHA3'} [algorithm="SHA256"]
 * @returns {String}
 */
export const hashString = (string, algorithm) => {
    let alg = 'SHA256';

    if (algorithm) {
        alg = algorithm.toUpperCase();
    }

    switch (alg) {
        case 'MD5':
        case 'SHA1':
        case 'SHA224':
        case 'SHA256':
        case 'SHA384':
        case 'SHA512':
        case 'SHA3':
            return CryptoJS[alg](string).toString();
        default:
            return 'Unsuported algorithm - please use one of the following: MD5, SHA1, SHA224, SHA256, SHA384, SHA512, SHA3';
    }
};

/**
 * @param {string} string
 * @returns {string}
 */
export const upperCaseFirstLetter = (string) => {
    if (typeof string === 'string' && string.length > 1) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return '';
};
