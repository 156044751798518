export default {
    inputField: {
        borderRadius: '3px',
        position: 'relative',
        fontSize: '13px',
        color: '#333',
        lineHeight: '15px',
        width: '100%',
        padding: '10px 12px',
        height: '1rem',
        minHeight: '1rem',

        '&:focus': {
            color: '#333',
        },
    },
    textAreaFieldRoot: {
        padding: '9.2px 12px',
    },
    textAreaField: {
        borderRadius: '3px',
        position: 'relative',
        fontSize: '13px',
        color: '#333',
        height: 'auto',
        padding: 0,

        '&:focus': {
            border: '0px solid #C4C4C4',
            color: '#111',
        },
    },
    selectField: {
        display: 'flex',
        alignItems: 'center',
    },
};
