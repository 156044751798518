import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import EllipsisIcon from '../../../assets/icons/EllipsisIcon';

const StepActionButton = ({ onClick, ariaLabel = 'show parameter menu', style = {}, sx = {}, disabled, ...props }) => {
    const inputButtonStyle = {
            padding: 0,
            height: '30px',
            width: '22px',
            minWidth: '10px',
            marginRight: '-8px',
            ...style,
        },
        iconStyle = { height: 16 };

    return (
        <InputAdornment position="end">
            <Button
                aria-label={ariaLabel}
                onClick={onClick}
                style={inputButtonStyle}
                sx={sx}
                disabled={disabled}
                {...props}
            >
                <EllipsisIcon style={iconStyle} />
            </Button>
        </InputAdornment>
    );
};

StepActionButton.propTypes = {
    sx: PropTypes.object,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default StepActionButton;
