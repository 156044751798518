/* eslint default-param-last: 0 */
import { MODAL_ACTIONS } from '../../hooks/useModalStateHook';
import reduxReducerHelper from '../../utils/reduxReducerHelperUtil';

const modalState = {
        open: false,
        actionType: '',
    },
    modalOpenState = { open: false },
    myAppModalState = { open: false, appData: {} },
    dataStoreModalState = { open: false, data: {} };

const INIT_STATE = {
    [MODAL_ACTIONS?.UPDATE_GENERATE_VISUAL_MODAL]: false,
    [MODAL_ACTIONS?.UPDATE_RENAME_INTEGRATION_MODAL]: false,
    [MODAL_ACTIONS?.UPDATE_GENERATE_VISUAL_MODAL_COUNTER]: 0,
    [MODAL_ACTIONS?.UPDATE_CONFIRM_UNSAVED_SETTINGS_MODAL]: false,
    [MODAL_ACTIONS?.UPDATE_CONFIRM_CREATE_FIRST_APPLICATION_MODAL]: false,
    [MODAL_ACTIONS?.TOGGLE_INTEGRATION_ACTION_MODAL]: modalState,
    [MODAL_ACTIONS?.TOGGLE_COPY_DELETE_DATA_MODAL]: { open: false, actionType: '', versionType: '', data: {} },
    [MODAL_ACTIONS?.UPDATE_OPEN_INTEGRATION_INFO_MODAL]: modalState,

    // Rosetta modals
    [MODAL_ACTIONS?.UPDATE_ROSETTA_DATA_TYPE_MODAL]: modalState,
    [MODAL_ACTIONS?.UPDATE_ROSETTA_MAPPING_INFO_MODAL]: modalState,
    [MODAL_ACTIONS?.UPDATE_ROSETTA_CREATE_MAPPING_MODAL]: modalState,
    [MODAL_ACTIONS?.UPDATE_ROSETTA_CUSTOM_FIELD_MODAL]: modalState,
    [MODAL_ACTIONS?.UPDATE_ROSETTA_MAPPING_OCCURENCE_MODAL]: modalState,
    [MODAL_ACTIONS?.UPDATE_ROSETTA_MAPPING_CONDITION_MODAL]: modalState,
    [MODAL_ACTIONS?.UPDATE_ROSETTA_MAPPING_CONDITION_FIELD_SELECTOR_MODAL]: {
        open: false,
        data: {},
    },

    // MyApps modals
    [MODAL_ACTIONS?.UPDATE_MYAPP_INFO_MODAL]: myAppModalState,
    [MODAL_ACTIONS?.UPDATE_MYAPP_INTERFACE_INFO_MODAL]: myAppModalState,
    [MODAL_ACTIONS?.UPDATE_APP_INSTANCE_WEBHOOK_INFO_MODAL]: myAppModalState,
    [MODAL_ACTIONS?.UPDATE_MYAPP_INTERFACE_WEBHOOK_INFO_MODAL]: myAppModalState,
    [MODAL_ACTIONS?.UPDATE_MYAPP_INTERFACE_RESOURCE_INFO_MODAL]: myAppModalState,
    [MODAL_ACTIONS?.UPDATE_MYAPP_INSTANCE_CREATE_WEBHOOK_MODAL]: modalOpenState,
    [MODAL_ACTIONS?.UPDATE_MYAPP_INTERFACE_INSTANCE_FIELD_INFO_MODAL]: myAppModalState,
    [MODAL_ACTIONS?.UPDATE_MYAPP_ADD_INPUT_TYPE_SELECT_OPTION_MODAL]: modalOpenState,
    [MODAL_ACTIONS?.UPDATE_MYAPP_ADD_RESOURCE_ACTION_RESPONSE_MODAL]: modalOpenState,
    [MODAL_ACTIONS?.UPDATE_MYAPP_ADD_RESOURCE_ACTION_PARAMETER_MODAL]: modalOpenState,
    [MODAL_ACTIONS?.UPDATE_MYAPP_ADD_RESOURCE_ACTION_CONSTRAINT_MODAL]: modalOpenState,
    [MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_RESOURCE_ACTION_INFO_MODAL]: modalOpenState,
    [MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_RESOURCE_ACTION_TEST_MODAL]: modalOpenState,

    // Delete data modal
    [MODAL_ACTIONS?.UPDATE_DELETE_DATA_MODAL]: modalOpenState,

    // Copy data modal
    [MODAL_ACTIONS?.UPDATE_COPY_DATA_MODAL]: modalOpenState,

    //My Data Types modals
    [MODAL_ACTIONS?.UPDATE_MYDATATYPE_CREATE_DATA_TYPE_MODAL]: modalState,
    [MODAL_ACTIONS?.UPDATE_MYDATATYPE_CREATE_FIELD_MODAL]: modalState,
    [MODAL_ACTIONS?.UPDATE_MYDATA_TYPE_INFO_MODAL]: {
        open: false,
        dataTypeData: {},
    },

    //Crosswalks modals
    [MODAL_ACTIONS?.UPDATE_CREATE_CROSSWALK_MODAL]: modalState,
    [MODAL_ACTIONS?.UPDATE_CROSSWALK_INFO_MODAL]: modalState,

    //VPN modals
    [MODAL_ACTIONS?.UPDATE_MY_CONNECTIONS_CREATE_CONNECTION_MODAL]: modalState,

    // Comparison Multiple Elements modals
    [MODAL_ACTIONS?.UPDATE_COMPARISONS_MULTIPLE_ELEMENTS_MODAL]: modalOpenState,

    // Data store modals
    [MODAL_ACTIONS?.UPDATE_DATA_STORE_HL7_MESSAGES_INFO_MODAL]: dataStoreModalState,
};

export default reduxReducerHelper(MODAL_ACTIONS, INIT_STATE);
