import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import store from '../../redux/store';
import { getStoreState } from '../useStoreStateHook';

import { sanitizeHL7MessageFilterValues } from '../../utils/dataStores/hl7Messages';

const storeNamespace = 'dataStoreHL7Messages';

/**
 * States
 */
export const dataStoreHL7MessagesStates = {
    selectedHL7V2AppInstanceId: '',
    hl7V2AppInstances: {
        isLoading: true,
        data: [],
    },
    hl7MessagesList: {
        isLoading: true,
        limit: 10,
        total: 0,
        page: 1,
        data: [],
        sort: { messageDateTime: 1 },
    },
    dataStoreHl7MessagesFilters: {
        isSubmittingFilters: false,
        apply: false,
        open: false,
        values: {
            messageTriggerEvent: '',
            patientIdentifier: '',
            attendingProvider: '',
            processedAtStart: '',
            messageDateStart: '',
            receivedAtStart: '',
            messageDateEnd: '',
            processedAtEnd: '',
            receivedAtEnd: '',
            visitNumber: '',
            messageCode: '',
            messageId: '',
            controlId: '',
            processed: '',
        },
    },
};

/**
 * Get the selected HL7 V2 App instance ID and subscribe to changes
 * @returns {string} HL7 V2 App instance ID
 */
export const useSelectedHL7V2AppInstanceId = () =>
    useSelector((state) => state?.[storeNamespace]?.selectedHL7V2AppInstanceId);

/**
 * Get the HL7 V2 App instances and subscribe to changes
 * @returns {object} appInstances HL7 V2 App instances
 * @property {Array<object>} data HL7 V2 App instances
 * @property {boolean} isLoading Loading state
 */
export const useHL7V2AppInstances = () => useSelector((state) => state?.[storeNamespace]?.hl7V2AppInstances);

/**
 * Get the data store HL7 messages states and subscribe to changes
 * @returns {object} Data store HL7 messages states
 * @property {Array<object>} data HL7 messages list
 * @property {object} sort Sort object
 * @property {number} total Total number of HL7 messages
 * @property {number} page Current page
 * @property {number} limit Number of items per page
 * @property {boolean} isLoading Loading state
 *
 */
export const useDataStoreHL7Messages = () => useSelector((state) => state?.[storeNamespace]?.hl7MessagesList);

/**
 * Get the data store HL7 messages filters states and subscribe to changes
 * @returns {object} Data store HL7 messages filters states
 * @property {boolean} apply Apply filters
 * @property {boolean} open Open filters
 * @property {object} values Filter values
 * @property {string} values.messageTriggerEvent Message trigger event
 * @property {string} values.patientIdentifier Patient identifier
 * @property {string} values.attendingProvider Attending provider
 * @property {string} values.processedAtStart Processed at start
 * @property {string} values.messageDateStart Message date start
 * @property {string} values.receivedAtStart Received at start
 * @property {string} values.messageDateEnd Message date end
 * @property {string} values.processedAtEnd Processed at end
 * @property {string} values.receivedAtEnd Received at end
 * @property {string} values.visitNumber Visit number
 * @property {string} values.messageCode Message code
 * @property {string} values.messageId Message ID
 * @property {string} values.controlId Control ID
 * @property {string} values.processed Processed
 */
export const useDataStoreHL7MessagesFilters = () =>
    useSelector((state) => state?.[storeNamespace]?.dataStoreHl7MessagesFilters);

export const useDataStoreHL7MessagesFilterValues = () => {
    const filtersState = useDataStoreHL7MessagesFilters();
    const filterValues = filtersState?.values || {};

    return useMemo(() => {
        return sanitizeHL7MessageFilterValues(filterValues);
    }, [filterValues]);
};

/**
 * Get the data store HL7 messages filters open state and subscribe to changes
 * @returns {boolean} Data store HL7 messages filters open state
 */
export const useDataStoreHL7MessagesFiltersOpenState = () =>
    useSelector((state) => state?.[storeNamespace]?.dataStoreHl7MessagesFilters?.open);

/**
 * Get the data store HL7 messages filters apply state and subscribe to changes
 * @returns {boolean} Data store HL7 messages filters apply state
 */
export const useDataStoreHL7MessagesFiltersApplyState = () =>
    useSelector((state) => state?.[storeNamespace]?.dataStoreHl7MessagesFilters?.apply);

/**
 * Update the selected HL7 V2 App instance ID
 *
 * @param {string} payload HL7 V2 App instance ID
 * @returns {void}
 */
export const setSelectedHL7V2AppInstanceId = (payload) => {
    store.dispatch({
        payload,
        type: 'selectedHL7V2AppInstanceId',
    });
};

/**
 * Update the HL7 V2 App instances
 *
 * @param {object} payload HL7 V2 App instances
 * @property {Array<object>} data HL7 V2 App instances
 * @property {boolean} isLoading Loading state
 * @returns {void}
 */
export const setHL7V2AppInstances = (payload) => {
    store.dispatch({
        payload,
        type: 'hl7V2AppInstances',
    });
};

/**
 * Update the data store HL7 messages
 *
 * @param {object} payload Data store HL7 messages
 * @property {Array<object>} data HL7 messages list
 * @property {number} total Total number of HL7 messages
 * @property {number} page Current page
 * @property {number} limit Number of items per page
 * @property {boolean} isLoading Loading state
 * @property {object} sort Sort object
 *
 * @returns {void}
 */
export const setDataStoreHL7Messages = (payload) => {
    store.dispatch({
        payload,
        type: 'hl7MessagesList',
    });
};

/**
 * Update the data store HL7 messages filters states
 * @param {object} payload
 * @property {boolean} payload.isSubmittingFilters Is submitting filters
 * @property {boolean} payload.apply Apply filters
 * @property {boolean} payload.open Open filters
 * @property {object} payload.values Filter values
 * @property {string} payload.values.messageTriggerEvent Message trigger event
 * @property {string} payload.values.patientIdentifier Patient identifier
 * @property {string} payload.values.attendingProvider Attending provider
 * @property {string} payload.values.processedAtStart Processed at start
 * @property {string} payload.values.messageDateStart Message date start
 * @property {string} payload.values.receivedAtStart Received at start
 * @property {string} payload.values.messageDateEnd Message date end
 * @property {string} payload.values.processedAtEnd Processed at end
 * @property {string} payload.values.receivedAtEnd Received at end
 * @property {string} payload.values.visitNumber Visit number
 * @property {string} payload.values.messageCode Message code
 * @property {string} payload.values.messageId Message ID
 * @property {string} payload.values.controlId Control ID
 * @property {string} payload.values.processed Processed
 * @returns {void}
 */
export const setDataStoreHL7MessagesFilters = (payload) => {
    store.dispatch({
        payload,
        type: 'dataStoreHl7MessagesFilters',
    });
};

/**
 * Get the data store HL7 messages filters states
 * @returns {object} Data store HL7 messages filters states
 * @property {boolean} apply Apply filters
 * @property {boolean} open Open filters
 * @property {object} values Filter values
 * @property {string} values.messageTriggerEvent Message trigger event
 * @property {string} values.patientIdentifier Patient identifier
 * @property {string} values.attendingProvider Attending provider
 * @property {string} values.processedAtStart Processed at start
 * @property {string} values.messageDateStart Message date start
 * @property {string} values.receivedAtStart Received at start
 * @property {string} values.messageDateEnd Message date end
 * @property {string} values.processedAtEnd Processed at end
 * @property {string} values.receivedAtEnd Received at end
 * @property {string} values.visitNumber Visit number
 * @property {string} values.messageCode Message code
 * @property {string} values.messageId Message ID
 * @property {string} values.controlId Control ID
 * @property {string} values.processed Processed
 */
export const getDataStoreHL7MessagesFilterState = () =>
    getStoreState(
        'dataStoreHl7MessagesFilters',
        storeNamespace,
    )(dataStoreHL7MessagesStates.dataStoreHl7MessagesFilters);

export const resetDataStoreHL7MessagesFilters = () => {
    store.dispatch({
        payload: {
            ...getDataStoreHL7MessagesFilterState(),
            values: dataStoreHL7MessagesStates.dataStoreHl7MessagesFilters.values,
            apply: false,
        },
        type: 'dataStoreHl7MessagesFilters',
    });
};

/**
 * Toggle the data store HL7 messages filters open state
 * @param {boolean} payload Open state
 */
export const toggleOpenDataStoreHL7MessagesFilters = (payload) => {
    const states = getDataStoreHL7MessagesFilterState();

    store.dispatch({
        payload: {
            ...states,
            open: payload,
        },
        type: 'dataStoreHl7MessagesFilters',
    });
};

/**
 * Apply the data store HL7 messages filters
 * @param {boolean} payload Apply filters
 */
export const applyDataStoreHL7MessagesFilters = (payload) => {
    const states = getDataStoreHL7MessagesFilterState();

    store.dispatch({
        payload: {
            ...states,
            apply: payload,
        },
        type: 'dataStoreHl7MessagesFilters',
    });
};

/**
 * Get the data store HL7 messages states
 *
 * @returns {object} Data store HL7 messages states
 * @property {Array<object>} data HL7 messages list
 * @property {object} sort Sort object
 * @property {number} total Total number of HL7 messages
 * @property {number} page Current page
 * @property {number} limit Number of items per page
 * @property {boolean} isLoading Loading state
 */
export const getDataStoreHL7MessagesState = () =>
    getStoreState('hl7MessagesList', storeNamespace)(dataStoreHL7MessagesStates.hl7MessagesList);
