import capitalizeFirstLetter from './capitalizeFirstLetter';
import {
    sendGetRequest,
    processResponse,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
} from './dataRequestUtils';
import { getCurrentSelectedOrganizationId } from './loginUtils';

const ignoreErrorCodes = [404];

/**
 * Get all apps
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getApps = ({
    successCallback,
    errorCallback,
    finallyCallback,
    pageNo = 1,
    showDeleted = false,
    includePublicApps = true,
    searchText = '',
    sorts = JSON.stringify({ name: 1 }),
} = {}) =>
    sendGetRequest({
        url: '/apps',
        data: { pageNo, includePublicApps, searchText, sorts, showDeleted },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch apps from server',
        }),
    });

/**
 * Get app by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppById = ({ appId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `/app/${appId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected app from server',
        }),
    });

/**
 * Create app
 * @param {Object} args
 * @param {Object} args.data New app data
 * @return {Promise<any>} Promise resolved
 */
export const createApp = ({ data, successCallback, errorCallback, finallyCallback }) =>
    sendPostRequest({
        data,
        url: '/app',
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to create app.',
            successMessage: 'App created successfully.',
        }),
    });

/**
 * Update app data
 * @param {Object} args
 * @param {String} args.appId App ID to update
 * @param {Object} args.data App data to update
 * @return {Promise<any>} Promise resolved
 */
export const updateApp = ({ appId, data, successCallback, errorCallback, finallyCallback }) =>
    sendPatchRequest({
        data,
        url: `/app/${appId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update app data.',
            successMessage: 'App data updated successfully.',
        }),
    });

/**
 * Get app interfaces by app ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfacesByAppId = ({ appId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interfaces`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected app interfaces from server',
            ignoreErrorCodes,
        }),
    });

/**
 * Get app interface resources by app ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceResourcesByInterfaceId = ({
    appId,
    interfaceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/resources`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected interface resources from server',
            ignoreErrorCodes,
        }),
    });

/**
 * Get app interface instance fields by app ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceInstanceFieldsByInterfaceId = ({
    appId,
    interfaceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/instanceFields`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch selected interface instance fields from server',
        }),
    });

/**
 * Get app interface by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceById = ({ appId, interfaceId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected interface from server',
        }),
    });

/**
 * Get app interface resource by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceResourceById = ({
    appId,
    interfaceId,
    resourceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/resource/${resourceId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected interface resource from server',
        }),
    });

/**
 * Get app interface resource actions by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceResourceActionsById = ({
    appId,
    interfaceId,
    resourceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/resource/${resourceId}/actions`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch selected resource actions from server',
        }),
    });

/**
 * Get app interface resource action by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceResourceActionById = ({
    appId,
    actionId,
    resourceId,
    interfaceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/resource/${resourceId}/action/${actionId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected resource action from server',
        }),
    });

/**
 * Get app interface instance-field by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceInstanceFieldById = ({
    appId,
    interfaceId,
    instanceFieldId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/instanceField/${instanceFieldId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected instance field from server',
        }),
    });

/**
 * Add app interface
 * @param {Object} args
 * @param {Object} args.data New app interface data
 * @return {Promise<any>} Promise resolved
 */
export const addAppInterface = ({ appId, data, successCallback, errorCallback, finallyCallback }) =>
    sendPostRequest({
        data,
        url: `/app/${appId}/interface`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to add interface to the app.',
            successMessage: 'Interface added successfully.',
        }),
    });

/**
 * Update app interface
 * @param {Object} args
 * @param {Object} args.data App interface data
 * @return {Promise<any>} Promise resolved
 */
export const updateAppInterface = ({
    appId,
    interfaceId,
    data,
    successCallback,
    errorCallback,
    finallyCallback,
    errorMessage = 'Unable to update interface.',
    successMessage = 'Interface updated successfully.',
}) =>
    sendPatchRequest({
        data,
        url: `/app/${appId}/interface/${interfaceId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage,
            successMessage,
        }),
    });

/**
 * Add app interface resource
 * @param {Object} args
 * @param {Object} args.data New app interface resource data
 * @return {Promise<any>} Promise resolved
 */
export const addAppInterfaceResource = ({
    appId,
    interfaceId,
    data,
    successCallback,
    errorCallback,
    finallyCallback,
}) =>
    sendPostRequest({
        data,
        url: `/app/${appId}/interface/${interfaceId}/resource`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to add resource.',
            successMessage: 'Resource added successfully.',
        }),
    });

/**
 * Update app interface resource
 * @param {Object} args
 * @param {Object} args.data App interface resource data
 * @return {Promise<any>} Promise resolved
 */
export const updateAppInterfaceResource = ({
    data,
    appId,
    interfaceId,
    resourceId,
    successCallback,
    errorCallback,
    finallyCallback,
}) =>
    sendPatchRequest({
        data,
        url: `/app/${appId}/interface/${interfaceId}/resource/${resourceId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update resource.',
            successMessage: 'Resource updated successfully.',
        }),
    });

/**
 * Add app interface resource action
 * @param {Object} args
 * @param {Object} args.data App interface resource action data
 * @return {Promise<any>} Promise resolved
 */
export const addAppInterfaceResourceAction = ({
    data,
    appId,
    interfaceId,
    resourceId,
    successCallback,
    errorCallback,
    finallyCallback,
}) =>
    sendPostRequest({
        data,
        url: `/app/${appId}/interface/${interfaceId}/resource/${resourceId}/action`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to add action.',
            successMessage: 'Action added successfully.',
        }),
    });

/**
 * Update app interface resource action
 * @param {Object} args
 * @param {Object} args.data App interface resource action data
 * @return {Promise<any>} Promise resolved
 */
export const updateAppInterfaceResourceAction = ({
    data,
    appId,
    actionId,
    interfaceId,
    resourceId,
    successCallback,
    errorCallback,
    finallyCallback,
}) =>
    sendPatchRequest({
        data,
        url: `/app/${appId}/interface/${interfaceId}/resource/${resourceId}/action/${actionId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update action.',
            successMessage: 'Action updated successfully.',
        }),
    });

/**
 * Add app interface instance-field
 * @param {Object} args
 * @param {Object} args.data App interface instance-field data
 * @return {Promise<any>} Promise resolved
 */
export const addAppInterfaceInstanceField = ({
    data,
    appId,
    interfaceId,
    successCallback,
    errorCallback,
    finallyCallback,
}) =>
    sendPostRequest({
        data,
        url: `/app/${appId}/interface/${interfaceId}/instanceField`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to add instance field.',
            successMessage: 'Instance field added successfully.',
        }),
    });

/**
 * Update app interface instance-field
 * @param {Object} args
 * @param {Object} args.data App interface instance-field data
 * @return {Promise<any>} Promise resolved
 */
export const updateAppInterfaceInstanceField = ({
    data,
    appId,
    interfaceId,
    instanceFieldId,
    successCallback,
    errorCallback,
    finallyCallback,
}) =>
    sendPatchRequest({
        data,
        url: `/app/${appId}/interface/${interfaceId}/instanceField/${instanceFieldId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update instance field.',
            successMessage: 'Instance field updated successfully.',
        }),
    });

/**
 * Handle delete app data
 * @param {Object} args
 * @return {Promise<any>}
 */
export const deleteMyAppData = ({
    appId,
    actionId,
    resourceId,
    interfaceId,
    instanceFieldId,
    interfaceWebhookId,
    successCallback,
    errorCallback,
    finallyCallback,
    label = 'data',
    errorLabel = '',
    successLabel = '',
}) => {
    let apiPath = `/app/${appId}/`;

    if (interfaceId) {
        apiPath += `interface/${interfaceId}/`;

        if (interfaceWebhookId) {
            apiPath += `webhook/${interfaceWebhookId}/`;
        } else if (resourceId) {
            apiPath += `resource/${resourceId}/`;

            if (actionId) {
                apiPath += `action/${actionId}/`;
            }
        } else if (instanceFieldId) {
            apiPath += `instanceField/${instanceFieldId}/`;
        }
    }

    apiPath += 'markDeleted';

    return sendPatchRequest({
        url: apiPath,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: `Unable to delete ${errorLabel || label}.`,
            successMessage: `${successLabel || capitalizeFirstLetter(label)} deleted successfully.`,
        }),
    });
};

export const restoreMyApp = ({ appId, successCallback, errorCallback, finallyCallback }) => {
    return sendPatchRequest({
        url: `/app/${appId}/unmarkDeleted`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: `Unable to restore app.`,
            successMessage: `Restored app successfully.`,
        }),
    });
};

export const deleteAllAppVersions = ({ documentId, successCallback, errorCallback, finallyCallback }) =>
    sendDeleteRequest({
        url: `apps/versions/${documentId}/markAllDeleted`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to delete version data.',
            successMessage: 'Version data deleted successfully.',
        }),
    });

export const restoreAllAppVersions = ({ documentId, successCallback, errorCallback, finallyCallback }) =>
    sendPatchRequest({
        url: `apps/versions/${documentId}/markAllRestored`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to restore version data.',
            successMessage: 'Version data restored successfully.',
        }),
    });

/**
 * Get selected app instances
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getSelectedAppInstances = ({ appId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `organization/${getCurrentSelectedOrganizationId()}/app/${appId}/instances`,
        data: {},
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch app instances from server',
        }),
    });

/**
 * Test resource action
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const executeAppInstanceResourceAction = ({
    appId,
    instanceId,
    resourceId,
    actionId,
    successCallback,
    errorCallback,
    finallyCallback,
    data = undefined,
    query = '',
    config = {},
} = {}) =>
    sendPostRequest({
        url: `app/${appId}/instance/${instanceId}/request/${resourceId}/${actionId}${query}`,
        data,
        config,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'An error occurred, unable to execute action',
        }),
    });

/**
 * Add app interface webhook
 * @param {Object} args
 * @param {Object} args.data New app interface webhook data
 * @return {Promise<any>} Promise resolved
 */
export const addAppInterfaceWebhook = ({ appId, interfaceId, data, successCallback, errorCallback, finallyCallback }) =>
    sendPostRequest({
        data,
        url: `/app/${appId}/interface/${interfaceId}/webhook`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to add webhook.',
            successMessage: 'Webhook added successfully.',
        }),
    });

/**
 * Add app interface webhook
 * @param {Object} args
 * @param {Object} args.data New app interface webhook data
 * @return {Promise<any>} Promise resolved
 */
export const updateAppInterfaceWebhook = ({
    appId,
    interfaceId,
    interfaceWebhookId,
    data,
    successCallback,
    errorCallback,
    finallyCallback,
}) =>
    sendPatchRequest({
        data,
        url: `/app/${appId}/interface/${interfaceId}/webhook/${interfaceWebhookId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update webhook.',
            successMessage: 'Webhook updated successfully.',
        }),
    });

/**
 * Get app interface webhook by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceWebhookById = ({
    appId,
    interfaceId,
    interfaceWebhookId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/webhook/${interfaceWebhookId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
        }),
    });

/**
 * Get app interface webhooks
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceWebhooks = ({
    appId,
    interfaceId,
    successCallback,
    errorCallback,
    finallyCallback,
    pageNo = 1,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/webhooks`,
        data: { pageNo },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
        }),
    });
