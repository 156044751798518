import {
    sendGetRequest,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
    processResponse,
} from './dataRequestUtils';

/**
 * Get all crosswalks
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getAllCrosswalks = ({ successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: 'rosetta/crosswalks/versions',
        data: {
            pageNo: null,
            sorts: JSON.stringify({ name: 1 }),
        },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to fetch crosswalks.',
        }),
    });

/**
 * Get crosswalks
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getCrosswalks = ({
    pageNo = 1,
    searchText = '',
    sorts = { name: 1 },
    showDeleted,
    successCallback,
    errorCallback,
    finallyCallback,
}) =>
    sendGetRequest({
        url: 'rosetta/crosswalks',
        data: {
            pageNo,
            searchText,
            showDeleted,
            sorts: JSON.stringify(sorts),
        },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to fetch crosswalks.',
        }),
    });

/**
 * Get crosswalk by id
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getCrosswalkById = ({ crosswalkId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `rosetta/crosswalk/${crosswalkId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to fetch crosswalk.',
        }),
    });

/**
 * Create crosswalk
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const createCrossWalk = ({ data, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPostRequest({
        url: 'rosetta/crosswalk',
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to create crosswalk.',
            successMessage: 'Crosswalk created successfully.',
        }),
    });

/**
 * Update crosswalk
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const updateCrossWalk = ({ crosswalkId, data, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPatchRequest({
        url: `rosetta/crosswalk/${crosswalkId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to update crosswalk',
            successMessage: 'Crosswalk updated successfully.',
        }),
    });

/**
 * Update crosswalk values
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const updateCrossWalkValues = ({ crosswalkId, values, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPatchRequest({
        url: `rosetta/crosswalk/${crosswalkId}/values`,
        data: { values },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to update crosswalk values',
            successMessage: 'Crosswalk values updated successfully.',
        }),
    });

/**
 * Delete crosswalk
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const deleteCrossWalk = ({ crosswalkId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendDeleteRequest({
        url: `rosetta/crosswalk/${crosswalkId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to delete crosswalk',
            successMessage: 'Crosswalk deleted successfully.',
        }),
    });

/**
 * @param documentId
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const deleteAllCrosswalkVersions = ({ documentId, successCallback, errorCallback, finallyCallback }) =>
    sendDeleteRequest({
        url: `rosetta/crosswalks/versions/${documentId}/deleteAll`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to delete version data.',
            successMessage: 'Version data deleted successfully.',
        }),
    });

/**
 * @param documentId
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const restoreAllCrosswalkVersions = ({ documentId, successCallback, errorCallback, finallyCallback }) =>
    sendPatchRequest({
        url: `rosetta/crosswalks/versions/${documentId}/restoreAll`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to restore version data.',
            successMessage: 'Version data restored successfully.',
        }),
    });

/**
 * @param crosswalkId
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const restoreCrosswalk = ({ crosswalkId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPatchRequest({
        url: `rosetta/crosswalk/${crosswalkId}/restore`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to restore crosswalk',
            successMessage: 'Crosswalk restored successfully.',
        }),
    });

/**
 * Copy crosswalk
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const copyCrosswalk = ({ crosswalkId, crosswalkName, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPostRequest({
        url: `rosetta/crosswalk/${crosswalkId}/copy`,
        data: { name: crosswalkName },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to copy crosswalk',
            successMessage: 'Crosswalk copied successfully.',
        }),
    });
