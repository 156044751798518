import { authorizedAxiosInstance } from '../axiosInstances';
import { /*addIntegrationUsage,*/ setupAppUsageRequestData } from './appUsageUtils';
import * as constants from './constants';
import { processResponse, sendGetRequest } from './dataRequestUtils';
import { logError } from './envUtils';
import HttpStatusCodes from '../classes/HttpStatusCodes';

const BASE_URL = process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST;

const ignoreErrorCodes = [404];

export const getIntegrationById = (id) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .get(`${BASE_URL}/integration/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

/**
 * @param integrationId
 * @param integrationName
 * @returns {Promise<unknown>}
 */
export const copyIntegration = ({ integrationId, integrationName }) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .post(`${BASE_URL}/integration/${integrationId}/copy`, {
                name: integrationName,
            })
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

/**
 * @param {string} integrationId
 * @returns {Promise<unknown>}
 */
export const restoreIntegration = (integrationId) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .patch(`${BASE_URL}/integration/${integrationId}/restore`)
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

export const insertIntegrationData = (status, name, description, organizationId, options = {}) => {
    return new Promise((resolve, reject) => {
        const body = {
            status: status,
            name: name,
            description: description,
            organizationId: organizationId,
            ...options,
        };
        authorizedAxiosInstance
            .post(`${BASE_URL}/integration/insert`, body)
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

/**
 * @param {string} integrationId
 * @returns {Promise<unknown>}
 */
export const terminateIntegrationExecution = (integrationId) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .request({
                url: `${BASE_URL}/integration/${integrationId}/terminate`,
                method: 'delete',
            })
            .then((res) => {
                if (res.status === 200) {
                    resolve(true);
                } else {
                    reject(false);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Updates specified fields of an integration.
 * @param id
 * @param integrationData
 * @returns {Promise<{}|boolean>}
 */
export const updateSelectIntegrationFields = async (id, integrationData) => {
    try {
        const response = await authorizedAxiosInstance.patch(`${BASE_URL}/integration/${id}`, integrationData);

        if (response.status === HttpStatusCodes.SUCCESS) {
            return response.data;
        }
    } catch (err) {
        logError(err?.response?.data?.error);
    }

    return false;
};

/**
 * Update integration data
 * @param {Object} integrationData
 * @param {Object} overrideIntegrationData
 * @returns {Promise<*>}
 */
export const updateIntegration = (integrationData, overrideIntegrationData = {}) => {
    return new Promise((resolve, reject) => {
        const body = {
            id: integrationData._id,
            name: integrationData.name,
            steps: integrationData.steps,
            status: constants.INTEGRATION_STATUS_PENDING,
            description: integrationData.description,
            organization: integrationData.organizationId,
            variables: integrationData.variables,
            counters: integrationData.counters,
            ...overrideIntegrationData,
        };

        authorizedAxiosInstance
            .patch(`${BASE_URL}/integration/${integrationData._id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

export const updateIntegrationName = (id, name) => {
    return new Promise((resolve, reject) => {
        const body = {
            name: name,
        };
        authorizedAxiosInstance
            .patch(`${BASE_URL}/integration/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

export const updateIntegrationStatus = ({ integrationId, status }) => {
    return new Promise((resolve, reject) => {
        const body = {
            status,
        };
        authorizedAxiosInstance
            .patch(`${BASE_URL}/integration/${integrationId}`, body)
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

export const getIntegrations = ({ pageNo = 1, searchText = '', sorts = { name: 1 }, showDeleted }) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .get(`${BASE_URL}/integrations`, {
                params: { pageNo, searchText, showDeleted, sorts: JSON.stringify(sorts) },
            })
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

export const deleteIntegration = (id) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .delete(`${BASE_URL}/integration/${id}`, {})
            .then((res) => {
                if (res.status === HttpStatusCodes.SUCCESS) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

/**
 * @param {string} documentId
 * @returns {Promise<unknown>}
 */
export const deleteAllIntegrationVersions = ({ documentId }) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .delete(`${BASE_URL}/integrations/versions/${documentId}/deleteAll`, {})
            .then((res) => {
                if (res.status === HttpStatusCodes.SUCCESS) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

/**
 * @param {string} documentId
 * @returns {Promise<unknown>}
 */
export const restoreAllIntegrationVersions = ({ documentId }) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .patch(`${BASE_URL}/integrations/versions/${documentId}/restoreAll`, {})
            .then((res) => {
                if (res.status === HttpStatusCodes.SUCCESS) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

export const deactivateIntegration = (id) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .patch(`${BASE_URL}/integration/${id}/setStatusPending`, {})
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);
            });
    });
};

/**
 * Test Integration Trigger/Execution
 * @param {Object} args
 * @param {Object} args.data
 * @return {Promise<any>}
 */
export const testExecuteIntegration = ({ integrationId, data = {} /*isExecuteMode = false*/ } = {}) => {
    return new Promise((resolve, reject) => {
        const body = {
            ...data,
            ignoreStatus: true,
        };

        setupAppUsageRequestData({
            type: 'integration',
            requestData: body,
        });

        authorizedAxiosInstance
            .post(`${BASE_URL}/execute_integration/${integrationId}`, body)
            .then((res) => {
                if (res.status === 200) {
                    // isExecuteMode && addIntegrationUsage({ res, success: true });
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                // isExecuteMode && addIntegrationUsage({ res: err, success: false });
                reject(err?.response?.data);
            });
    });
};

/**
 * Get all integrations by user
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getAllIntegrations = ({ successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `/integrations`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
        }),
    });

/**
 * Get all integrations by user
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getAllIntegrationsVersions = ({ successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `/integrations/versions`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
        }),
    });

/**
 *
 * @param integrationId
 * @param pageNo
 * @param searchText
 * @param sorts
 * @param filters
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const getIntegrationExecutions = ({
    integrationId,
    pageNo,
    searchText,
    sorts = { createdAt: 1 },
    filters = {},
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/integration/${integrationId}/executions`,
        data: {
            pageNo,
            searchText,
            sorts: JSON.stringify(sorts),
            filters: filters,
        },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
        }),
    });

/**
 *
 * @param integrationId
 * @param executionId
 * @param intelyStorageLogFilePath
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const getIntegrationExecutionLog = ({
    integrationId,
    executionId,
    intelyStorageLogFilePath,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/integration/${integrationId}/execution/${executionId}/log`,
        data: {
            intelyStorageLogFilePath,
        },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
        }),
    });
