import {
    sendGetRequest,
    processResponse,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
} from './dataRequestUtils';

/**
 * Get all Data Type categories
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getDataTypeCategoriesRequest = ({ successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: '/rosetta/dataTypes/categories',
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch data type categories',
        }),
    });

/**
 * Get all data types for current organization
 * @param {Object} args
 * @returns {Promise<DatabaseQueryResults>} response
 */
export const getAllDataTypes = ({ successCallback, errorCallback, finallyCallback }) =>
    sendGetRequest({
        url: '/rosetta/dataTypes',
        data: { myDataTypes: false },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch Data Types',
        }),
    });

/**
 * Get my data type for current organization for pageNo
 * @param {Object} args
 * @returns {Promise<DatabaseQueryResults>} response
 */
export const getMyDataTypes = ({
    pageNo = 1,
    myDataType = true,
    searchText = '',
    sorts = { name: 1 },
    showDeleted = false,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) => {
    return sendGetRequest({
        url: '/rosetta/dataTypes',
        data: { pageNo, myDataType: !!myDataType, searchText, sorts: JSON.stringify(sorts), showDeleted },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch Data Types',
        }),
    });
};

/**
 * Get specific data type with data type id
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getMyDataType = ({ dataTypeId, successCallback, errorCallback, finallyCallback }) =>
    sendGetRequest({
        url: `/rosetta/dataType/${dataTypeId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch Data Type',
        }),
    });

/**
 * Add Data Type to organization
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const createMyDataType = ({ data, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPostRequest({
        url: '/rosetta/dataType',
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully created Data Type',
            errorMessage: 'Unable to add Data Type',
        }),
    });

/**
 * Update Data Type
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const updateMyDataType = ({ dataTypeId, data, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPatchRequest({
        url: `/rosetta/dataType/${dataTypeId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully updated Data Type',
            errorMessage: 'Unable to update Data Type',
        }),
    });

/**
 * Delete Data Type
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const deleteMyDataType = ({ dataTypeId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendDeleteRequest({
        url: `/rosetta/dataType/${dataTypeId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully deleted Data Type',
            errorMessage: 'Unable to delete Data Type',
        }),
    });

/**
 * @param documentId
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const deleteAllDataTypeVersions = ({ documentId, successCallback, errorCallback, finallyCallback }) =>
    sendDeleteRequest({
        url: `rosetta/dataTypes/versions/${documentId}/deleteAll`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to delete data type version data.',
            successMessage: 'Data type version data deleted successfully.',
        }),
    });

/**
 * @param documentId
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const restoreAllDataTypeVersions = ({ documentId, successCallback, errorCallback, finallyCallback }) =>
    sendPatchRequest({
        url: `rosetta/dataTypes/versions/${documentId}/restoreAll`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to restore data type version data.',
            successMessage: 'Data type version data restored successfully.',
        }),
    });

/**
 * Get specific data type for export
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const exportMyDataType = ({
    dataTypeId,
    dependentDataTypes,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendPostRequest({
        url: `/rosetta/dataType/${dataTypeId}/export`,
        data: { dependentDataTypes },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to export data type',
        }),
    });

/**
 * Import Data Type to organization
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const importMyDataType = ({ data, successCallback, errorCallback, finallyCallback } = {}) => {
    sendPostRequest({
        url: `/rosetta/dataTypes/import`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully imported Data Type',
            errorMessage: 'Unable to import Data Type',
        }),
    });
};

/**
 * Copy Data Type to organization
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const copyMyDataType = ({ dataTypeId, dataTypeName, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPostRequest({
        url: `/rosetta/dataType/${dataTypeId}/copy`,
        data: { name: dataTypeName },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully copied Data Type',
            errorMessage: 'Unable to copy Data Type',
        }),
    });

/**
 * @param dataTypeId
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const restoreMyDataType = async ({ dataTypeId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPatchRequest({
        url: `/rosetta/dataType/${dataTypeId}/restore`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully restored data type',
            errorMessage: 'Unable to restore data type',
        }),
    });
