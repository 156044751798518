import { useSelector } from 'react-redux';
import store from '../redux/store';

export const MODAL_ACTIONS = {
    UPDATE_GENERATE_VISUAL_MODAL: 'generateVisualModal',
    UPDATE_RENAME_INTEGRATION_MODAL: 'renameIntegrationModal',
    TOGGLE_INTEGRATION_ACTION_MODAL: 'toggleIntegrationDialogActionModal',
    TOGGLE_COPY_DELETE_DATA_MODAL: 'toggleDialogCopyDeleteModal',
    UPDATE_GENERATE_VISUAL_MODAL_COUNTER: 'generateVisualModalCounter',
    UPDATE_CONFIRM_UNSAVED_SETTINGS_MODAL: 'confirmUnsavedSettingsModal',
    UPDATE_CONFIRM_CREATE_FIRST_APPLICATION_MODAL: 'confirmCreateFirstApplicationModal',
    UPDATE_OPEN_INTEGRATION_INFO_MODAL: 'openCreateModifyIntegrationModal',

    /**
     * Rosetta Modal
     */
    UPDATE_ROSETTA_DATA_TYPE_MODAL: 'rosettaDataTypeModal',
    UPDATE_ROSETTA_MAPPING_INFO_MODAL: 'rosettaMappingInfoModal',
    UPDATE_ROSETTA_CREATE_MAPPING_MODAL: 'rosettaCreateMappingModal',
    UPDATE_ROSETTA_CUSTOM_FIELD_MODAL: 'rosettaCustomFieldModal',
    UPDATE_ROSETTA_MAPPING_OCCURENCE_MODAL: 'rosettaMappingOccurenceModal',
    UPDATE_ROSETTA_MAPPING_CONDITION_MODAL: 'rosettaMappingConditionModal',
    UPDATE_ROSETTA_MAPPING_CONDITION_FIELD_SELECTOR_MODAL: 'rosettaMappingConditionFieldSelectorModal',

    /**
     * MyApps Modal
     */
    UPDATE_MYAPP_INFO_MODAL: 'myAppInfoModal',
    UPDATE_MYAPP_INTERFACE_INFO_MODAL: 'myAppInterfaceInfoModal',
    UPDATE_APP_INSTANCE_WEBHOOK_INFO_MODAL: 'appInstanceWebhookInfoModal',
    UPDATE_MYAPP_INTERFACE_WEBHOOK_INFO_MODAL: 'myAppInterfaceWebhookInfoModal',
    UPDATE_MYAPP_INTERFACE_RESOURCE_INFO_MODAL: 'myAppInterfaceResourceInfoModal',
    UPDATE_MYAPP_INSTANCE_CREATE_WEBHOOK_MODAL: 'myAppAddInstanceCreateWebhookModal',
    UPDATE_MYAPP_INTERFACE_INSTANCE_FIELD_INFO_MODAL: 'myAppInterfaceInstanceFieldInfoModal',
    UPDATE_MYAPP_ADD_INPUT_TYPE_SELECT_OPTION_MODAL: 'myAppAddInputTypeSelectOptionModal',
    UPDATE_MYAPP_ADD_RESOURCE_ACTION_RESPONSE_MODAL: 'myAppAddResourceActionResponseModal',
    UPDATE_MYAPP_ADD_RESOURCE_ACTION_PARAMETER_MODAL: 'myAppAddResourceActionParameterModal',
    UPDATE_MYAPP_ADD_RESOURCE_ACTION_CONSTRAINT_MODAL: 'myAppAddResourceActionConstraintModal',
    UPDATE_MYAPP_INTERFACE_RESOURCE_ACTION_INFO_MODAL: 'myAppInterfaceResourceActionInfoModal',
    UPDATE_MYAPP_INTERFACE_RESOURCE_ACTION_TEST_MODAL: 'myAppInterfaceResourceActionTestModal',

    /**
     * Delete data modal
     */
    UPDATE_DELETE_DATA_MODAL: 'deleteDataModal',

    /**
     * Copy data modal
     */
    UPDATE_COPY_DATA_MODAL: 'copyDataModal',

    /**
     * My Data Types Modal
     */
    UPDATE_MYDATATYPE_CREATE_DATA_TYPE_MODAL: 'myDataTypeCreateDataTypeModal',
    UPDATE_MYDATATYPE_CREATE_FIELD_MODAL: 'myDataTypeAddFieldModal',
    UPDATE_MYDATA_TYPE_INFO_MODAL: 'myDataTypeInfoModal',

    /**
     * Crosswalks Modal
     */
    UPDATE_CREATE_CROSSWALK_MODAL: 'crosswalksCreateModal',
    UPDATE_CROSSWALK_INFO_MODAL: 'crosswalkInfoModal',

    /**
     * VPN Connection Modal
     */
    UPDATE_MY_CONNECTIONS_CREATE_CONNECTION_MODAL: 'myConnectionsCreateConnectionModal',

    /**
     * Comparisons Multiple Elements Modal
     */
    UPDATE_COMPARISONS_MULTIPLE_ELEMENTS_MODAL: 'comparisonsMultipleElementsModal',

    /**
     * Data Store Modal
     */
    UPDATE_DATA_STORE_HL7_MESSAGES_INFO_MODAL: 'dataStoreHL7MessagesInfoModal',
};

/**
 * Get the modal state
 * @param {string} modalType Modal type to bind to.
 * @param {object} state Store States - useSelector(state => state)
 * @returns {mixed} Current modal state value.
 */
export const getModalState = (state, modalType) => state?.modals?.[modalType];

/**
 * Get the modal state and subscribe to it using the useSelector() hook.
 * @param {string} modalType Modal type to bind to.
 * @returns {mixed} Current modal state value.
 */
export const useGetModalState = (modalType) => {
    return useSelector((state) => getModalState(state, modalType));
};

/**
 * Toggle the modal visibility
 * @param {string} type Modal type to bind to.
 * @param {boolean} payload New modal state value.
 * @returns {Function}
 */
export const setModalState = (type) => (payload) => {
    store.dispatch({ type, payload });
};

/**
 * Allows you to access and set the create integration modal state.
 * Behaves like the React useState() hook.
 * @param {string} modalType Modal type to bind to.
 * @see {MODAL_ACTIONS} for supported modal type actions
 * @returns {array}
 * - isModalOpened: (boolean) Specify whether the modal is opened or closed.
 * - setModalState: (callable) A function to be called to set the modal state.
 */
const useModalState = (modalType) => {
    const isModalOpened = useSelector((state) => getModalState(state, modalType));
    const toggleModalState = setModalState(modalType);

    return [isModalOpened, toggleModalState];
};

export const toggleModifyIntegrationModal = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_OPEN_INTEGRATION_INFO_MODAL)(state);
};

/**
 * Toggle create first application modal
 * @param {Boolean} open
 */
export const toggleConfirmCreateFirstApplicationModal = (open) => {
    setModalState(MODAL_ACTIONS.UPDATE_CONFIRM_CREATE_FIRST_APPLICATION_MODAL)(open);
};

/**
 * Toggle rename integration modal
 * @param {Boolean} open
 */
export const toggleRenameIntegrationModal = (open) => {
    setModalState(MODAL_ACTIONS.UPDATE_RENAME_INTEGRATION_MODAL)(open);
};

/**
 * Toggle generate visual modal state
 * @param {boolean} state True to open the modal, otherwise false.
 */
export const toggleGenerateVisualModal = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_GENERATE_VISUAL_MODAL)(state);
};

/**
 * Toggle confirm unsaved settings modal state
 * @param {boolean} state True to open the modal, otherwise false.
 */
export const toggleConfirmUnsavedSettingsModal = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_CONFIRM_UNSAVED_SETTINGS_MODAL)(state);
};

/**
 * Set the integration action modal state
 * @param {object} state
 * @param {string} state.actionType The action type
 * @param {boolean} state.open Whether modal is open or not.
 */
export const setIntegrationActionModalState = (state) => {
    setModalState(MODAL_ACTIONS.TOGGLE_INTEGRATION_ACTION_MODAL)(state);
};

/**
 * @param state
 */
export const setCopyDeleteDataModal = (state) => {
    setModalState(MODAL_ACTIONS.TOGGLE_COPY_DELETE_DATA_MODAL)(state);
};

/**
 * Toggle generate visual modal state
 * @param {number} counter
 */
export const setGenerateVisualModalCounter = (counter) => {
    setModalState(MODAL_ACTIONS.UPDATE_GENERATE_VISUAL_MODAL_COUNTER)(counter);
};

/**
 * Toggle rosetta mapping info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.mappingData Mapping data
 */
export const setRosettaShowMappingInfoModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_ROSETTA_MAPPING_INFO_MODAL)(state);
};

/**
 * Toggle my-app info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.appData App data
 */
export const setMyAppShowInfoModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_INFO_MODAL)(state);
};

/**
 * Toggle my-app interface info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.appData App data
 */
export const setMyAppInterfaceShowInfoModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_INFO_MODAL)(state);
};

/**
 * Toggle my-app interface resource info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.appData App data
 */
export const setMyAppInterfaceResourceInfoModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_RESOURCE_INFO_MODAL)(state);
};

/**
 * Toggle my-app interface resource action info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.appData App data
 */
export const setMyAppInterfaceResourceActionInfoModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_RESOURCE_ACTION_INFO_MODAL)(state);
};

/**
 * Toggle my-app interface resource action test modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.appData App data
 */
export const setMyAppInterfaceResourceActionTestModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_RESOURCE_ACTION_TEST_MODAL)(state);
};

/**
 * Toggle my-app interface instance field info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.appData App data
 */
export const setMyAppInterfaceInstanceFieldInfoModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_INSTANCE_FIELD_INFO_MODAL)(state);
};

/**
 * Toggle my-app interface webhook info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.appData App data
 */
export const setMyAppInterfaceWebhookInfoModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_WEBHOOK_INFO_MODAL)(state);
};

/**
 * Toggle my-app instance webhook info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.appData App data
 */
export const setMyAppInstanceWebhookInfoModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_APP_INSTANCE_WEBHOOK_INFO_MODAL)(state);
};

/**
 * Toggle my-app add input type select option modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.inputTypeData Input type data
 */
export const setMyAppAddInputTypeSelectOptionModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_ADD_INPUT_TYPE_SELECT_OPTION_MODAL)(state);
};

/**
 * Toggle my-app add resource action response modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data modal data
 */
export const setMyAppAddResourceActionResponseModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_ADD_RESOURCE_ACTION_RESPONSE_MODAL)(state);
};

/**
 * Toggle my-app instance create webhook modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data modal data
 */
export const setMyAppInstanceCreateWebhookModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_INSTANCE_CREATE_WEBHOOK_MODAL)(state);
};

/**
 * Toggle my-app add resource action parameter modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data modal data
 */
export const setMyAppAddResourceActionParameterModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_ADD_RESOURCE_ACTION_PARAMETER_MODAL)(state);
};

/**
 * Toggle my-app add resource action constraint modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data modal data
 */
export const setMyAppAddResourceActionConstraintModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_MYAPP_ADD_RESOURCE_ACTION_CONSTRAINT_MODAL)(state);
};

/**
 * Toggle rosetta create mapping modal state
 * @param {object} state
 * @param {string} state.actionType The action type
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.mappingData Mapping data if action type is 'update' | 'delete'
 *
 * Note: action types: 'create' | 'update' | 'delete'
 */
export const setRosettaCreateMappingModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_ROSETTA_CREATE_MAPPING_MODAL)(state);
};

/**
 * Toggle rosetta data type modal state
 * @param {object} state
 * @param {string} state.actionType The action type
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data Mapping data if action type is 'update' | 'delete'
 *
 * Note: action types: 'create' | 'update' | 'delete'
 */
export const setRosettaDataTypeModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_ROSETTA_DATA_TYPE_MODAL)(state);
};

/**
 * Toggle rosetta custom field modal
 * @param {object} state
 * @param {string} state.actionType The action type
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data data
 *
 * Note: action types: 'delete'
 */
export const setRosettaCustomFieldModalState = (state) =>
    setModalState(MODAL_ACTIONS.UPDATE_ROSETTA_CUSTOM_FIELD_MODAL)(state);

/**
 * Toggle rosetta mapping occurence modal
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data Input id, Output and input path
 */
export const setRosettaMappingOccurenceModalState = (state) =>
    setModalState(MODAL_ACTIONS.UPDATE_ROSETTA_MAPPING_OCCURENCE_MODAL)(state);

/**
 * Toggle rosetta mapping condition modal
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.name name
 * @param {object} state.actionType action type
 */
export const setRosettaMappingConditionModalState = (state) =>
    setModalState(MODAL_ACTIONS.UPDATE_ROSETTA_MAPPING_CONDITION_MODAL)(state);

/**
 * Toggle rosetta mapping condition field selector modal
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data
 */
export const setRosettaMappingConditionFieldSelectorModalState = (state) =>
    setModalState(MODAL_ACTIONS.UPDATE_ROSETTA_MAPPING_CONDITION_FIELD_SELECTOR_MODAL)(state);

/**
 * Toggle my data type modal
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.dataTypeData Data type data
 *
 * Note: action types: 'create' | 'update' | 'delete'
 */
export const setMyDataTypeCreateDataTypeModalState = (state) =>
    setModalState(MODAL_ACTIONS.UPDATE_MYDATATYPE_CREATE_DATA_TYPE_MODAL)(state);

/**
 * Toggle my data type fields modal
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.fieldData field data
 * @param {object} state.parent parent data
 *
 * Note: action types: 'create' | 'update' | 'delete'
 */
export const setMyDataTypeCreateFieldModalState = (state) =>
    setModalState(MODAL_ACTIONS.UPDATE_MYDATATYPE_CREATE_FIELD_MODAL)(state);

/**
 * Toggle my data type info modal
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.dataTypeData field data
 *
 */
export const setMyDataTypeInfoModalState = (state) => setModalState(MODAL_ACTIONS.UPDATE_MYDATA_TYPE_INFO_MODAL)(state);

/**
 * Toggle crosswalk create modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data Crosswalk data
 * @returns
 */
export const setCrosswalkCreateModalState = (state) =>
    setModalState(MODAL_ACTIONS.UPDATE_CREATE_CROSSWALK_MODAL)(state);

/**
 * Toggle crosswalk info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data Crosswalk data
 */
export const setCrosswalkInfoModalState = (state) => setModalState(MODAL_ACTIONS.UPDATE_CROSSWALK_INFO_MODAL)(state);

/**
 * Toggle data store HL7 messages info modal state
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.data Data
 */
export const setDataStoreHL7MessagesModalInfoState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_DATA_STORE_HL7_MESSAGES_INFO_MODAL)(state);
};

/**
 * Get the generate visual modal state
 * @returns {boolean} State
 */
export const useGetGenerateVisualModalState = () => useGetModalState(MODAL_ACTIONS.UPDATE_GENERATE_VISUAL_MODAL);

/**
 * Get the generate visual modal counter
 * @returns {number} Counter
 */
export const useGetGenerateVisualModalCounter = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_GENERATE_VISUAL_MODAL_COUNTER);

/**
 * Get the integration action modal state
 * @returns {boolean} State
 */
export const useGetIntegrationActionModalState = () => useGetModalState(MODAL_ACTIONS.TOGGLE_INTEGRATION_ACTION_MODAL);

/**
 * @typedef CopyDeleteDataState
 * @property {boolean} open
 * @property {'copy'|'delete'} actionType
 * @property {VersionType} versionType
 * @property {Object} data
 */

/**
 * @returns {CopyDeleteDataState}
 */
export const useGetSetCopyDeleteDataModal = () => useGetModalState(MODAL_ACTIONS.TOGGLE_COPY_DELETE_DATA_MODAL);

/**
 * Get the integration action modal state
 * @returns {boolean} State
 */
export const useGetRenameIntegrationModalState = () => useGetModalState(MODAL_ACTIONS.UPDATE_RENAME_INTEGRATION_MODAL);

/**
 * Get the rosetta create|update|delete action modal state
 * @returns {object} State
 */
export const useGetRosettaCreateMappingModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_ROSETTA_CREATE_MAPPING_MODAL);

/**
 * Get the rosetta create|update|delete data type action modal state
 * @returns {object} State
 */
export const useGetRosettaDataTypeModalState = () => useGetModalState(MODAL_ACTIONS.UPDATE_ROSETTA_DATA_TYPE_MODAL);

/**
 * Get the rosetta info modal state
 * @returns {object} State
 */
export const useGetRosettaInfoMappingModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_ROSETTA_MAPPING_INFO_MODAL);

/**
 * Get the my-app info modal state
 * @returns {object} State
 */
export const useGetMyAppInfoModalState = () => useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_INFO_MODAL);

/**
 * Get the my-app interface info modal state
 * @returns {object} State
 */
export const useGetMyAppInterfaceInfoModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_INFO_MODAL);

/**
 * Get the my-app interface resource info modal state
 * @returns {object} State
 */
export const useGetMyAppInterfaceResourceInfoModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_RESOURCE_INFO_MODAL);

/**
 * Get the my-app interface resource action info modal state
 * @returns {object} State
 */
export const useGetMyAppInterfaceResourceActionInfoModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_RESOURCE_ACTION_INFO_MODAL);

/**
 * Get the my-app interface resource action test modal state and subscribe to changes
 * @returns {object} State
 */
export const useGetMyAppInterfaceResourceActionTestModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_RESOURCE_ACTION_TEST_MODAL);

/**
 * Get the my-app interface resource action test modal state
 * @returns {object} State
 */
export const getMyAppInterfaceResourceActionTestModalState = () =>
    getModalState(store.getState(), MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_RESOURCE_ACTION_TEST_MODAL) || {};

/**
 * Get the my-app interface instance field info modal state
 * @returns {object} State
 */
export const useGetMyAppInterfaceInstanceFieldInfoModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_INSTANCE_FIELD_INFO_MODAL);

/**
 * Get the my-app interface webhook info modal state
 * @returns {object} State
 */
export const useGetMyAppInterfaceWebhookInfoModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_INTERFACE_WEBHOOK_INFO_MODAL);

/**
 * Get the my-app instance webhook info modal state
 * @returns {object} State
 */
export const useGetMyAppInstanceWebhookInfoModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_APP_INSTANCE_WEBHOOK_INFO_MODAL);

/**
 * Get the my-app add input type select option modal state
 * @returns {object} State
 */
export const useGetMyAppAddInputTypeSelectOptionModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_ADD_INPUT_TYPE_SELECT_OPTION_MODAL);

/**
 * Get the my-app add resource action response modal state
 * @returns {object} State
 */
export const useGetMyAppAddResourceActionResponseModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_ADD_RESOURCE_ACTION_RESPONSE_MODAL);

/**
 * Get the my-app instance create webhook modal state
 * @returns {object} State
 */
export const useGetMyAppInstanceCreateWebhookModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_INSTANCE_CREATE_WEBHOOK_MODAL);

/**
 * Get the my-app add resource action parameter modal state
 * @returns {object} State
 */
export const useGetMyAppAddResourceActionParameterModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_ADD_RESOURCE_ACTION_PARAMETER_MODAL);

/**
 * Get the my-app add resource action constraint modal state
 * @returns {object} State
 */
export const useGetMyAppAddResourceActionConstraintModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYAPP_ADD_RESOURCE_ACTION_CONSTRAINT_MODAL);

/**
 * Get rosetta custom fields modal state
 * @returns {object} State
 */
export const useGetRosettaCustomFieldsModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_ROSETTA_CUSTOM_FIELD_MODAL);

/**
 * Get rosetta mapping occurence modal state
 * @returns {object} State
 */
export const useGetRosettaMappingOccurenceModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_ROSETTA_MAPPING_OCCURENCE_MODAL);

/**
 * Get rosetta mapping condition modal state
 * @returns {object} State
 */
export const useGetRosettaMappingConditionModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_ROSETTA_MAPPING_CONDITION_MODAL);

/**
 * Get rosetta mapping condition field selector modal state
 * @returns {object} State
 */
export const useGetRosettaMappingConditionFieldSelectorModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_ROSETTA_MAPPING_CONDITION_FIELD_SELECTOR_MODAL);

/**
 * Get the data type create|update|delete action modal state
 * @returns {object} State
 */
export const useGetMyDataTypeCreateDataTypeModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYDATATYPE_CREATE_DATA_TYPE_MODAL);

/**
 * Get the field create|update|delete action modal state
 * @returns {object} State
 */
export const useGetMyDataTypeCreateFieldModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MYDATATYPE_CREATE_FIELD_MODAL);

/**
 * Get the my data type info modal state
 * @returns {object} State
 */
export const useGetMyDataTypeInfoModalState = () => useGetModalState(MODAL_ACTIONS.UPDATE_MYDATA_TYPE_INFO_MODAL);

/**
 * Get the my crosswalks create|update|delete action modal state
 * @returns {object} State
 */
export const useCreateCrosswalkModalState = () => useGetModalState(MODAL_ACTIONS.UPDATE_CREATE_CROSSWALK_MODAL);

/**
 * Get the crosswalks info modal state
 * @returns {object} State
 */
export const useGetCrosswalkInfoModalState = () => useGetModalState(MODAL_ACTIONS.UPDATE_CROSSWALK_INFO_MODAL);

/**
 * Replacement popup for old create integration modal.
 * @returns {Object}
 */
export const useModifyIntegrationModal = () => useModalState(MODAL_ACTIONS.UPDATE_OPEN_INTEGRATION_INFO_MODAL);

/**
 * Integration action modal hook
 * @returns {array<boolean, function>}
 */
export const useIntegrationActionModal = () => useModalState(MODAL_ACTIONS.TOGGLE_INTEGRATION_ACTION_MODAL);

/**
 * @returns {Array}
 */
export const useCopyDeleteDataModal = () => useModalState(MODAL_ACTIONS.TOGGLE_COPY_DELETE_DATA_MODAL);

/**
 * Rename integration modal hook
 * @returns {array<boolean, function>}
 */
export const useRenameIntegrationModal = () => useModalState(MODAL_ACTIONS.UPDATE_RENAME_INTEGRATION_MODAL);

/**
 * Get the delete modal state data and subscribe to changes.
 * @returns {Array<object>}
 */
export const useDeleteDataModalState = () => useGetModalState(MODAL_ACTIONS.UPDATE_DELETE_DATA_MODAL);

/**
 * Get the delete modal state data and subscribe to changes.
 * @param {Object} state
 * @param {Boolean} state.open
 * @param {String} state.label
 * @param {Function} state.handleDelete
 * @param {Object} state.sx
 * @param {*} state.modalTitle
 */
export const setDeleteDataModalState = (state) => {
    setModalState(MODAL_ACTIONS.UPDATE_DELETE_DATA_MODAL)(state);
};

/**
 * Get the copy modal state data and subscribe to changes.
 * @returns {Array<object>}
 */
export const useCopyDataModalState = () => useGetModalState(MODAL_ACTIONS.UPDATE_COPY_DATA_MODAL);

/**
 * Set copy modal state data.
 * @param {Object} state
 * @param {Boolean} state.open
 * @param {String} state.label
 * @param {Function} state.handleCopy
 * @param {Function} state.customValidation
 * @param {Object} state.sx
 * @param {*} state.modalTitle
 */
export const setCopyDataModalState = (state) => setModalState(MODAL_ACTIONS.UPDATE_COPY_DATA_MODAL)(state);

/**
 * Toggle my servers modal
 * @param {object} state
 * @param {boolean} state.open Whether modal is open or not.
 * @param {object} state.connectionData connection data
 *
 * Note: action types: 'create' | 'update' | 'delete' | 'info'
 */
export const setMyConnectionsCreateConnectionModalState = (state) =>
    setModalState(MODAL_ACTIONS.UPDATE_MY_CONNECTIONS_CREATE_CONNECTION_MODAL)(state);

/**
 * Get the my servers create|update|delete action modal state
 * @returns {object} State
 */
export const useGetMyConnectionsCreateConnectionModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_MY_CONNECTIONS_CREATE_CONNECTION_MODAL);

/**
 *
 * @param {Object} state
 * @param {Boolean} state.open Whether modal is open or not.
 */
export const setComparisonsMultipleElementsModalState = (state) =>
    setModalState(MODAL_ACTIONS.UPDATE_COMPARISONS_MULTIPLE_ELEMENTS_MODAL)(state);

/**
 * Get the comparisons multiple elements modal state and subscribe to changes.
 * @returns {Object} State
 */
export const useComparisonsMultipleElementsModalState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_COMPARISONS_MULTIPLE_ELEMENTS_MODAL);

/**
 *  Get the data store HL7 messages modal info state and subscribe to changes.
 * @returns {object} state State
 * @property {boolean} state.open Whether modal is open or not.
 * @property {object} state.data Data
 */
export const useGetDataStoreHL7MessagesModalInfoState = () =>
    useGetModalState(MODAL_ACTIONS.UPDATE_DATA_STORE_HL7_MESSAGES_INFO_MODAL);
