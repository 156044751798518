import TextField from '@mui/material/TextField';
import styles from '../RosettaInputField/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import LockResetIcon from '@mui/icons-material/LockReset';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
import { getEncryptedValue, getDecryptedValue } from '../../../utils/stringUtils';
import CircularProgress from '@mui/material/CircularProgress';
import StepActionButton from '../../Buttons/StepActionButton';
import { showSnackBarErrorNotification } from '../../../utils/snackBarNotificationUtil';

/**
 * @typedef EncryptedStringInformation
 * @property {boolean} isEncrypted
 * @property {boolean} hasChanged
 */

/**
 * @typedef FieldProps
 * @property {string} name
 * @property {Object} sx
 * @property {boolean} required
 * @property {boolean} fullWidth
 * @property {string} variant
 * @property {boolean} error
 * @property {boolean} disabled
 * @property {Object} inputProps
 */

/**
 * @typedef EncryptedStringInputFieldProps
 * @property {FieldProps} textFieldProps
 * @property {string} organizationId
 * @property {string|IntegrationStepParameter} encryptedValue
 * @property {boolean} isAdding
 * @property {(e: Event, v: EncryptedStringInformation) => void?} onChange
 * @property {func?} onClick
 */

export const ENCRYPTED_STRING_INPUT_MODE_CHANGE = 'change';
export const ENCRYPTED_STRING_INPUT_MODE_CLICK = 'click';
export const ENCRYPTED_STRING_INPUT_MODE_INTEGRATION_STEP = 'step';

/**
 * @param {EncryptedStringInputFieldProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const EncryptedStringInputField = (props) => {
    const textFieldProps = props.textFieldProps;
    const isAdding = props.isAdding;
    const isStepParameterValue = typeof props?.encryptedValue === 'object';
    const additionalIconStyle = props.iconStyle || {};
    const stepReferenceFunction = props?.stepReferenceFunction || false;
    const [isDisplayingData, setIsDisplayingData] = useState(isAdding);
    const [hasEditedString, setHasEditedString] = useState(false);
    const [encryptedValue, setEncryptedValue] = useState(/** @type {IntegrationStepParameter|string} */ '');
    const [initialValue, setInitialValue] = useState('');
    const [currentValue, setCurrentValue] = useState('');
    const [triggerOnClick, setTriggerOnClick] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const organizationId = props.organizationId;
    const sx = {
        ...{
            height: '40px',
            width: '100%',
            backgroundColor: '#fff',
            borderRadius: '8px',
            fontSize: '14px !important',
            marginTop: '7px',
            color: 'black',
            '& input': {
                border: 'none !important',
            },
            userSelect: 'none !important',
        },
        ...(textFieldProps?.sx || {}),
        ...{
            '& .MuiInputBase-input': { ...styles.inputField, fontSize: '14px !important' },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor:
                    textFieldProps?.error === true
                        ? '#FF0000 !important'
                        : textFieldProps?.sx?.['& .MuiOutlinedInput-notchedOutline']?.borderColor
                          ? textFieldProps.sx['& .MuiOutlinedInput-notchedOutline'].borderColor
                          : 'inherit',
            },
        },
    };
    let isCurrentlyLocked = true;
    let inputType = 'password';
    const generalIconProps = {
        style: {
            position: 'absolute',
            height: '28px',
            width: '28px',
            top: 0,
            bottom: 0,
            right: 5,
            margin: 'auto',
            fill: !hasEditedString || isCurrentlyLocked ? '#333' : '#ff0000',
            cursor: 'pointer',
            ...additionalIconStyle,
        },
    };
    let valueToDisplay = '';
    let iconProps = {
        ...generalIconProps,
    };
    const inputProps = {};

    useEffect(() => {
        setEncryptedValue(props?.encryptedValue || '');
    }, [props?.encryptedValue]);

    useEffect(() => {
        if (props?.resetState === true) {
            setEncryptedValue(props?.encryptedValue);
            setInitialValue('');
            setCurrentValue('');
            setHasEditedString(false);
            setIsDisplayingData(isAdding);
        }
    }, [props?.resetState]);

    switch (props.mode) {
        case ENCRYPTED_STRING_INPUT_MODE_CHANGE:
            iconProps = {
                ...iconProps,
                onClick: () => {
                    setIsDisplayingData(!isDisplayingData);
                },
            };

            isCurrentlyLocked = (!isDisplayingData || isLoading) && !isAdding;
            valueToDisplay = isCurrentlyLocked ? 'abcdefghijklmnopqrstuvwxyz123456789' : currentValue;
            if (isCurrentlyLocked) {
                if (encryptedValue !== '') {
                    valueToDisplay = 'abcdefghijklmnopqrstuvwxyz123456789';
                } else {
                    valueToDisplay = '';
                }
            } else {
                valueToDisplay = currentValue;
            }
            inputType = isCurrentlyLocked ? 'password' : 'text';
            break;
        case ENCRYPTED_STRING_INPUT_MODE_CLICK:
            iconProps = {
                ...iconProps,
                onClick: async () => {
                    if (props.mode === ENCRYPTED_STRING_INPUT_MODE_CLICK) {
                        if (!isAdding) {
                            setIsLoading(true);
                            getDecryptedValue(organizationId, encryptedValue)
                                .then((decryptedValue) => {
                                    if (decryptedValue !== false) {
                                        setInitialValue(decryptedValue);
                                        setCurrentValue(decryptedValue);
                                        setTriggerOnClick(true);
                                    }
                                })
                                .finally(() => {
                                    setIsLoading(false);
                                });
                        } else {
                            setTriggerOnClick(true);
                        }
                    }
                },
            };

            isCurrentlyLocked = true;
            valueToDisplay = 'abcdefghijklmnopqrstuvwxyz123456789';
            inputType = 'password';
            break;
        case ENCRYPTED_STRING_INPUT_MODE_INTEGRATION_STEP:
            isCurrentlyLocked = !encryptedValue?.sourceDisplayLabel;

            if (typeof stepReferenceFunction === 'function') {
                inputProps.InputProps = {
                    endAdornment: <StepActionButton onClick={stepReferenceFunction} />,
                };
            }

            if (isStepParameterValue) {
                if (encryptedValue?.sourceDisplayLabel) {
                    valueToDisplay = encryptedValue.sourceDisplayLabel;
                    inputType = 'text';
                } else {
                    valueToDisplay = encryptedValue.value;
                    isCurrentlyLocked = false;
                    inputType = 'password';
                }
            } else {
                valueToDisplay = encryptedValue;
                inputType = 'password';
            }
            break;
    }

    useEffect(() => {
        if (isDisplayingData && !isLoading && !isAdding) {
            setIsLoading(true);
            getDecryptedValue(organizationId, encryptedValue)
                .then((decryptedValue) => {
                    if (decryptedValue !== false) {
                        setInitialValue(decryptedValue);
                        setCurrentValue(decryptedValue);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        return () => {};
    }, [isDisplayingData]);

    /**
     * @param {string} updatedValue
     * @returns {Promise<string|boolean>}
     */
    const handleOnClickValueSet = async (updatedValue) => {
        const encryptedUpdatedValue = await getEncryptedValue(organizationId, updatedValue);

        if (encryptedUpdatedValue !== false) {
            setIsDisplayingData(false);
            if (isStepParameterValue) {
                setEncryptedValue({
                    ...encryptedValue,
                    value: encryptedUpdatedValue,
                    sourceDisplayLabel: '',
                });
            } else {
                setEncryptedValue(encryptedUpdatedValue);
            }
            setCurrentValue('');
        }

        return encryptedUpdatedValue;
    };

    useEffect(() => {
        if (triggerOnClick) {
            setTriggerOnClick(false);

            switch (props.mode) {
                case ENCRYPTED_STRING_INPUT_MODE_CLICK:
                    if (typeof props?.onClick === 'function') {
                        props.onClick(currentValue, handleOnClickValueSet);
                    }
                    break;
                case ENCRYPTED_STRING_INPUT_MODE_INTEGRATION_STEP:
                    if (isStepParameterValue && typeof props?.onClick === 'function') {
                        getDecryptedValue(organizationId, encryptedValue?.value)
                            .then((decryptedValue) => {
                                props.onClick(decryptedValue || '', handleOnClickValueSet);
                            })
                            .catch(() => {
                                showSnackBarErrorNotification('Failed to decrypt value');
                            });
                    }
                    break;
            }
        }

        return () => {};
    }, [triggerOnClick]);

    return (
        <div style={{ position: 'relative', height: sx.height }}>
            <TextField
                {...textFieldProps}
                {...inputProps}
                sx={sx}
                disabled={isCurrentlyLocked || textFieldProps?.disabled}
                type={inputType}
                onChange={(e) => {
                    if (props.mode === ENCRYPTED_STRING_INPUT_MODE_CHANGE) {
                        if (isDisplayingData) {
                            setHasEditedString(initialValue !== e.target.value);
                            setCurrentValue(e.target.value);
                            textFieldProps.onChange(e, {
                                isEncrypted: !isDisplayingData && !isAdding,
                                hasChanged: hasEditedString,
                            });
                        }
                    }
                }}
                onClick={
                    props.mode === ENCRYPTED_STRING_INPUT_MODE_INTEGRATION_STEP
                        ? () => {
                              setTriggerOnClick(true);
                          }
                        : undefined
                }
                value={valueToDisplay || ''}
                autoComplete={'off'}
            />
            {
                <>
                    {!isAdding && props.mode === ENCRYPTED_STRING_INPUT_MODE_CHANGE && (
                        <>
                            {isDisplayingData && !isLoading && <LockResetIcon {...iconProps} />}
                            {!isDisplayingData && !isLoading && <LockIcon {...iconProps} />}
                        </>
                    )}
                    {props.mode === ENCRYPTED_STRING_INPUT_MODE_CLICK && !isLoading && <EditIcon {...iconProps} />}
                    {isLoading && <CircularProgress {...generalIconProps} disableShrink />}
                </>
            }
        </div>
    );
};

EncryptedStringInputField.propTypes = {
    organizationId: PropTypes.string.isRequired,
    encryptedValue: PropTypes.any.isRequired,
    isAdding: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
    stepReferenceFunction: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    textFieldProps: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        sx: PropTypes.object,
        required: PropTypes.bool,
        fullWidth: PropTypes.bool,
        variant: PropTypes.string,
        error: PropTypes.bool,
        disabled: PropTypes.bool,
        placeholder: PropTypes.string,
        inputProps: PropTypes.object,
    }),
    iconStyle: PropTypes.object,
    resetState: PropTypes.bool,
};

export default EncryptedStringInputField;
